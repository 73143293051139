import { Welcome, Onboarding, CreateService, CreateSpecialist } from "../pages/Onboarding";

export const adminOnboarding = {
  path: "onboarding",
  element: <Onboarding />,
  children: [
    {
      path: "welcome",
      element: <Welcome />,
    },
    {
      path: "services/create",
      element: <CreateService />,
    },
    {
      path: "specialists/create",
      element: <CreateSpecialist />,
    },
  ],
};
