import { FC } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormInput, schema } from "../parts/Form";

import { axiosInstance } from "../../../api/axiosConfig";
import { ScheduleEventApi } from "../../../types";
import { dispatchToast } from "../../../components/Toast";
import { PageFormModal } from "../../../layouts/PageFormModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { URL, ROUTES, START_DATE_KEY } from "../constants";
import { strToDate } from "../../../../utils/dateConvertor";
import dayjs from "dayjs";
import { navigateWithParams } from "../../../hooks/navigateWithParams";
import { filtersFamily, usePaginatedData } from "../../../stores/pagination";
import { useAppState } from "../../../stores/appMain";
import { useRecoilValue } from "recoil";
import { Warn } from "./Warn";

export const CreateModal: FC = () => {
  const [searchParams] = useSearchParams();
  const startDateStr = searchParams.get(START_DATE_KEY);
  const { mutate: mutateList } = usePaginatedData(URL, null, true);
  const { specialistId } = useRecoilValue(filtersFamily(URL)); // selected specialist
  const isBefore = dayjs(startDateStr).isBefore(dayjs(), "day");
  const navigate = useNavigate();

  const services = useAppState
    .services()
    .value.map((item) => ({ value: item.id, label: item.title, data: { ...item } }));
  const specialists = useAppState.specialists().value.map(({ id: value, name: label }) => ({ value, label }));

  const onCreate = async (values: FormInput) => {
    const { data } = await axiosInstance.post<ScheduleEventApi>(URL, values);
    if (data?.id) {
      dispatchToast({ message: "Successfully created." });
      mutateList((list) => (list ? [data, ...list] : [data]), true);
      toMain();
    }
  };

  const toMain = () =>
    navigateWithParams(navigate, {
      newPath: `/${ROUTES.main}`,
      paramsToRemove: [START_DATE_KEY],
    });

  if (isBefore) return <Warn />;

  return (
    <PageFormModal onClose={toMain}>
      <Form
        onSubmit={onCreate}
        init={{
          specialistId,
          startDate: strToDate(startDateStr),
          endDate: dayjs(startDateStr).add(1, "hour").toDate(),
          offerIds: [1],
        }}
        resolver={zodResolver(schema)}
        services={services}
        specialists={specialists}
      />
    </PageFormModal>
  );
};
