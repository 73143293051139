import { Box, Button, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

type Props = {
  actionBtn?: ReactNode;
};

export const Header: FC<Props> = ({ actionBtn }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        paddingY: "10px",
      }}
    >
      <Button to="/" component={Link} sx={{ textTransform: "none" }}>
        My logo
      </Button>
      {actionBtn}
    </Box>
  );
};
