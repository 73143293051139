import { FC, createContext } from "react";

import { FormInput } from "./types";
import { ClientInfo } from "./parts/ClientInfo";
import { Services } from "./parts/Services";
import { SelectOption, SelectOptionService } from "../../../../../types";
import { SubmitBtn } from "./parts/SubmitBtn";
import { Title } from "./parts/Title";
import { schema } from "./schema";
import MainForm from "../../../../components/MainForm";
import { Resolver, SubmitHandler } from "react-hook-form";
import { Box, Divider } from "@mui/material";
import { Select, TextField } from "../../../../components/MainForm/inputs";
import { DateTimePicker } from "./parts/DateTimePicker";

export { type FormInput, ClientInfo, Services, type SelectOption, SubmitBtn, Title, schema };

type Props = {
  onSubmit: SubmitHandler<FormInput>;
  services: SelectOptionService[];
  specialists: SelectOption[];
  isUpdate?: boolean;
  init?: unknown;
  resolver?: Resolver<any, any>;
};

export const Form: FC<Props> = ({ onSubmit, services, init, resolver, isUpdate, specialists }) => {
  const title = isUpdate ? "Update" : "Create";
  return (
    <MainForm
      init={init}
      onSubmit={onSubmit}
      resolver={resolver}
      sx={{
        width: "420px",
        maxWidth: "100%",
        marginX: "auto",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <TextField name="id" hidden />
        <Box sx={{ display: "flex", alignItems: "start", pt: "5px" }}>
          <Box sx={{ width: "50%", mr: "15px", ml: 0 }}>
            <Select
              name="specialistId"
              label="Specialist"
              size="small"
              options={specialists}
              required
              variant="standard"
              FormControlProps={{ sx: { mt: 0 } }}
              disabled={specialists.length === 1}
            />
          </Box>
          <Title title={title} subtitle="appointment" sx={{ pt: "8px", mx: "auto" }} />
        </Box>
        <ClientInfo />
        <Divider sx={{ m: "0 0 7px" }} />
        <DateTimePicker />
        <Divider sx={{ m: "7px 0 7px" }} />
        <Services options={services} />
        <Divider sx={{ m: "12px 0 7px" }} />
      </Box>
      <Box>
        <SubmitBtn>{title}</SubmitBtn>
      </Box>
    </MainForm>
  );
};

export const FormContext = createContext({});
