import { atom } from "recoil";

export type Specialist = {
  id: number;
  isActive: boolean;
  name: string;
  specialization: string;
  selected?: boolean; // use for filters
};

export const specialistData = atom<Specialist[]>({
  key: "specialistData",
  default: [],
});
