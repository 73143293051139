import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { dispatchToast } from "../components/Toast";

export { AxiosError as SubmitError };

export const TOKEN_KEY = "token";
export const STATUS_CODE_LOGOUT = "[401]";

//TODO Add an error page if there is no connection to the server

const createAxiosInstance = (baseURL: string, timeout: number): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    timeout,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use(
    (config) => {
      const token = Cookies.get(TOKEN_KEY);
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if ((error.code = "ERR_NETWORK")) {
        dispatchToast({
          type: "error",
          message: error.message,
        });
      }
      if (error.response) {
        if (error.response.status === 401) {
          window.location.href = `/auth/login?${STATUS_CODE_LOGOUT}=true`;
        } else if (error.response.data?.message || error.response.data?.error) {
          dispatchToast({
            type: "error",
            message: error.response.data?.message || error.response.data?.error,
          });
        }
      } else if (error.code === "ECONNABORTED") {
        dispatchToast({
          type: "error",
          message: "Request timeout. Please try again later.",
        });
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
export const axiosInstance = createAxiosInstance(process.env.REACT_APP_API_URL || "/api", 10000);

export const fetcher = (url: string) => axiosInstance.get(url).then(({ data }) => data);
