import React, { FC } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar, PickersDay, PickersDayProps, PickersCalendarHeader } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { IconButton, Typography } from "@mui/material";
import { useAppState } from "../../stores/appMain";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

const Day: FC<PickersDayProps<Dayjs> & { date: Dayjs }> = ({ date, ...restProps }) => {
  const isSelected = date ? dayjs(restProps.day).isSame(date, "day") : false;

  return (
    <PickersDay
      {...restProps}
      sx={{
        borderRadius: "6px",
        ...(isSelected && {
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.primary.contrastText,
          "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.dark,
          },
        }),
      }}
    />
  );
};

type Props = {
  toggleDrawer: React.MouseEventHandler<HTMLButtonElement>;
  previewMode?: boolean;
};

export const Calendar: React.FC<Props> = ({ previewMode, toggleDrawer }) => {
  const { value, setValue } = useAppState.selectedDate();

  const date = dayjs(value);
  const setSelectedDate = (value: Dayjs) => setValue(value.toDate());
  const onPrev = () => setValue((prev) => dayjs(prev).add(1, "days").toDate());
  const onNext = () => setValue((prev) => dayjs(prev).add(-1, "days").toDate());

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {previewMode && (
        <Typography
          variant="h6"
          sx={{
            writingMode: "vertical-rl",
            transform: "rotateZ(180deg)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton onClick={onPrev} size="small">
            <ArrowCircleLeftOutlinedIcon
              sx={{
                fontSize: "30px",
              }}
            />
          </IconButton>
          <IconButton size="small" color="primary">
            {date?.format("DD/MM/YYYY")}
          </IconButton>
          <IconButton onClick={onNext} size="small">
            <ArrowCircleRightOutlinedIcon
              sx={{
                fontSize: "30px",
              }}
            />
          </IconButton>
        </Typography>
      )}
      <DateCalendar
        value={date}
        onChange={(newValue) => setSelectedDate(newValue)}
        views={["day"]}
        sx={{
          "&.MuiDateCalendar-root": {
            width: "200px",
            margin: 0,
            padding: 0,
            height: "auto",
            ...(previewMode && { opacity: 0, width: 0, overflow: "hidden" }),
          },
          "& .MuiDayCalendar-loadingContainer": {
            minHeight: "auto",
          },
          "& .MuiPickersSlideTransition-root": {
            minHeight: "auto",
          },
          "& .MuiDayCalendar-monthContainer": {
            position: "relative",
          },
          "& .MuiPickersDay-root": {
            height: "27.43px",
            margin: 0,
            mx: "1px",
            // width: "27.43px",
          },
        }}
        slots={{
          day: (props) => {
            return <Day {...props} date={date} />;
          },
          calendarHeader: (props) => (
            <PickersCalendarHeader
              {...props}
              sx={{
                "&.MuiPickersCalendarHeader-root": {
                  mt: 0,
                  px: 0,
                },
              }}
            />
          ),
        }}
        reduceAnimations
        showDaysOutsideCurrentMonth
      />
    </LocalizationProvider>
  );
};
