import { auth } from "./auth";
import { adminOnboarding } from "./adminOnboarding";
import { adminHome } from "./adminHome";

import AuthCheck from "../layouts/AuthCheck";
import ErrorPage from "../pages/ErrorPage";
import { AuthProvider } from "../context/AuthContext";
import { ToastHandler } from "../components/Toast";

export function adminRoute(path: string) {
  return {
    path,
    element: (
      <>
        <ToastHandler />
        <AuthProvider />
      </>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "auth",
        children: [auth],
      },
      {
        path: "",
        element: <AuthCheck />,
        children: [adminOnboarding, adminHome],
      },
    ],
  };
}
