import { FC, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Form } from "./Form";
import { FormInput } from "./types";
import { AuthRoot } from "../../../layouts/AuthRoot";
import { Button, Typography } from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";

const init = {
  phoneCode: "+1",
  phone: "",
  email: "",
  acceptPolicy: false,
};

export const SigningUp: FC = () => {
  const navigate = useNavigate();
  const { signUp } = useContext(AuthContext);

  const onSubmit = async (data: FormInput) => {
    const result = await signUp({
      ...data,
      phoneNumber: `${data.phoneCode}${data.phone}`,
    });
    if (result?.data?.id) navigate("/onboarding/welcome");
  };

  return (
    <AuthRoot
      actionBtn={
        <Button to="../login" component={Link} variant="outlined">
          <Typography sx={{ textTransform: "none" }}>Sign In</Typography>
        </Button>
      }
    >
      <Form onSubmit={onSubmit} init={init} />
    </AuthRoot>
  );
};
