import React, { useState } from "react";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

type Weekday = "sun" | "mon" | "tue" | "wed" | "thu" | "fri" | "sat";

type Props = {
  onChange: (selectedDays: string[]) => void;
  value: string[];
};

export const WeekdaysSelector: React.FC<Props> = ({ value, onChange }) => {
  const handleWeekdayChange = (
    event: React.MouseEvent<HTMLElement>,
    newDays: Weekday[]
  ) => {
    onChange(newDays);
  };

  return (
    <Box sx={{ paddingTop: "5px" }}>
      <Typography marginBottom={0.5}>Working days</Typography>
      <ToggleButtonGroup
        value={value}
        onChange={handleWeekdayChange}
        aria-label="Working days"
      >
        {(["sun", "mon", "tue", "wed", "thu", "fri", "sat"] as Weekday[]).map(
          (day) => (
            <ToggleButton
              key={day}
              value={day}
              aria-label={`${day}`}
              color="primary"
              size="small"
              sx={{
                width: "43px",
                fontSize: "12px",
                paddingY: "4px",

                "&.MuiToggleButton-root": {
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  background: "transparent",
                  borderRadius: "6px",
                  margin: 0,
                  marginRight: "8px",
                  "&.Mui-selected": {
                    border: "1px solid rgba(24, 118, 210, 1)!important",
                    background: "transparent",
                    borderRadius: "6px",
                    margin: 0,
                    marginRight: "8px",
                  },
                },
                "&.MuiToggleButton-root:last-child": {
                  marginRight: 0,
                },
              }}
            >
              {day.toUpperCase()}
            </ToggleButton>
          )
        )}
      </ToggleButtonGroup>
    </Box>
  );
};
