import { FC } from "react";

import { Box, Typography, Link as MaterialLink } from "@mui/material";
import { FormInput } from "../types";
import { Link } from "react-router-dom";
import MainForm from "../../../../components/MainForm";
import {
  PasswordField,
  TextField,
} from "../../../../components/MainForm/inputs";
import { zodResolver } from "@hookform/resolvers/zod";
import { schema } from "./schema";
import { SubmitBtn } from "../../../../components/MainForm/SubmitBtn";

type Props = {
  onSubmit: (values: FormInput) => Promise<unknown>;
  init?: FormInput;
};

export const Form: FC<Props> = ({ onSubmit, init }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          maxWidth: "420px",
          marginTop: "100px",
        }}
      >
        <Typography
          component="h3"
          variant="h4"
          sx={{
            marginBottom: "20px",
          }}
        >
          Login
        </Typography>
        <MainForm
          onSubmit={onSubmit}
          resolver={zodResolver(schema)}
          init={init}
        >
          <div>
            <TextField name="login" label="Phone number or Email" />
            <PasswordField name="password" label="Password" />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <MaterialLink to="../reset-password" component={Link}>
                <Typography>Forgot password?</Typography>
              </MaterialLink>
            </Box>
            <SubmitBtn
              size="large"
              variant="contained"
              sx={{ marginTop: "20px" }}
            />
          </div>
        </MainForm>
      </Box>
    </Box>
  );
};
