import { SigningUp } from "../pages/Auth/SigningUp";
import { ResetPassword } from "../pages/Auth/ResetPassword";
import { Login } from "../pages/Auth/Login";

export const auth = {
  children: [
    {
      path: "registration",
      element: <SigningUp />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "reset-password",
      element: <ResetPassword />,
    },
  ],
};
