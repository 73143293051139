import { Box, Paper, Typography } from "@mui/material";
import { FC } from "react";
import { isInterim } from "../utils";
import { TICK_HEIGHT } from "../constants";
import { Title } from "./Title";
import { padZero } from "../../../../../../utils/index.";

export type TickPeriodTime = {
  hours: number;
  minutes: number;
};

type Props = {
  n: number;
  time: TickPeriodTime;
  disabled: boolean;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
};

export const TickPeriod: FC<Props> = (props) => {
  const { n: item, time, disabled, onClick } = props;

  return (
    <Box
      component="li"
      data-period-box="tick-period-10"
      sx={{
        display: "flex",
        height: TICK_HEIGHT,
      }}
      onClick={onClick}
    >
      <Typography
        sx={{
          fontSize: "10px",
          width: "20px",
          opacity: `${isInterim(item) ? "0" : "1"}`,
        }}
      >
        {time.minutes}
      </Typography>
      <Box
        sx={{
          borderTop: `${item !== 0 && "1px solid #ccc"}`,
          borderColor: `${isInterim(item) ? "#edecec" : "#ccc"}`,
          flex: 1,
          position: "relative",
          "&:hover .minutesRange": { display: disabled ? undefined : "block" },
        }}
      >
        <Paper
          className="minutesRange"
          sx={{
            borderRadius: "4px",
            background: "#44bf8e",
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: "none",
            paddingX: "10px",
          }}
        >
          <Title>
            {padZero(time.hours)}:{padZero(time.minutes)}
          </Title>
        </Paper>
      </Box>
    </Box>
  );
};
