import dayjs, { Dayjs } from "dayjs";

export function strToDate(data?: string | null): Date | undefined {
  return data ? dayjs(data).toDate() : undefined;
}

export function dateToStr(date?: Date | Dayjs | null): string | undefined {
  if (date) return date ? dayjs(date).format() : undefined;
}

type ConvertKeysToDate<T, K extends keyof T> = Omit<T, K> & {
  [P in K]: Date;
};

export function convertStrToDates<
  T extends Record<string, any>,
  K extends keyof T
>(data: T[], dateFields: K[]): ConvertKeysToDate<T, K>[] {
  if (!Array.isArray(data) || !Array.isArray(dateFields))
    return data as ConvertKeysToDate<T, K>[];

  return data.map((item) => {
    const newItem = { ...item } as ConvertKeysToDate<T, K>;
    dateFields.forEach((field) => {
      const dateValue = newItem[field] as unknown;
      if (typeof dateValue === "string" && dayjs(dateValue).isValid()) {
        newItem[field] = dayjs(dateValue).toDate() as unknown as T[K];
      }
    });
    return newItem;
  });
}
