import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

type Props = TextFieldProps & {
  name: string;
  label?: string;
};

const values = ["30", "60", "90"];

const CUSTOM = "45";

export const DurationPicker: React.FC<Props> = ({ name, label, ...props }) => {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const [isCustom, setIsCustom] = useState(false);

  const helpText = errors[name]?.message || props.helperText || " ";

  return (
    <Box sx={{ paddingTop: "5px" }}>
      <FormControl fullWidth error={!!errors[name]} margin="none">
        <Controller
          name={name}
          control={control}
          disabled={isSubmitting}
          render={({ field }) => {
            const updateMode = (vale: boolean) => {
              setIsCustom(vale);
            };
            return (
              <>
                <FormLabel id="duration-radio-buttons-group-label">
                  Duration
                </FormLabel>
                <RadioGroup
                  aria-labelledby="duration-radio-buttons-group-label"
                  value={isCustom ? CUSTOM : field.value}
                  onChange={field.onChange}
                  name="duration"
                  row
                >
                  {values.map((item) => (
                    <FormControlLabel
                      key={item}
                      value={item}
                      control={<Radio />}
                      label={`${item} Min`}
                      onClick={() => updateMode(false)}
                    />
                  ))}
                  <FormControlLabel
                    value={CUSTOM}
                    control={<Radio />}
                    onClick={() => updateMode(true)}
                    label="Custom"
                  />
                </RadioGroup>
                {isCustom && (
                  <TextField
                    value={field.value}
                    onChange={field.onChange}
                    type="number"
                    label="Duration Min"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Min</InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                )}
              </>
            );
          }}
        />
        <FormHelperText sx={{ m: 0, textAlign: "right" }}>
          {helpText as React.ReactNode}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};
