import { CreateModal, Main as Schedule, UpdateModal } from "../pages/Schedule";

import { Create as ServicesCreate, List as Services, Update as ServicesUpdate } from "../pages/Services";
import { Create as SpecialistsCreate, List as Specialists, Update as SpecialistsUpdate } from "../pages/Specialists";
import { AppBarNavBar } from "../layouts/AppBarNavBar";
import { Outlet, RouteObject } from "react-router-dom";

export const adminHome: RouteObject = {
  path: "",
  element: <AppBarNavBar />,
  children: [
    {
      path: "schedule",
      element: (
        <>
          <Schedule />
          <Outlet />
        </>
      ),
      children: [
        {
          path: "create",
          element: <CreateModal />,
        },
        {
          path: "update/:id",
          element: <UpdateModal />,
        },
      ],
    },
    {
      path: "services",
      element: (
        <>
          <Services />
          <Outlet />
        </>
      ),
      children: [
        {
          path: "create",
          element: <ServicesCreate />,
        },
        {
          path: "update/:id",
          element: <ServicesUpdate />,
        },
      ],
    },
    {
      path: "specialists",
      element: (
        <>
          <Specialists />
          <Outlet />
        </>
      ),
      children: [
        {
          path: "create",
          element: <SpecialistsCreate />,
        },
        {
          path: "update/:id",
          element: <SpecialistsUpdate />,
        },
      ],
    },
  ],
};
