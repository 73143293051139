type ToastProps = {
  type?: "success" | "warning" | "error";
  message: string;
  canDismissed?: boolean;
  timeout?: number;
};

type ToastEventData = {
  detail: {
    props: {
      type: string;
      message: string;
      canDismissed: boolean;
    };
    timeout: number;
  };
};

export const dispatchToast = ({
  type = "success",
  message,
  canDismissed = true,
  timeout = 3000,
}: ToastProps) => {
  const eventData: ToastEventData = {
    detail: {
      props: {
        type,
        message,
        canDismissed,
      },
      timeout,
    },
  };
  const toastEvent = new CustomEvent("toast", eventData);
  window.dispatchEvent(toastEvent);
};
