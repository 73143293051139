export const countryCodes = [
  { label: "US", value: "+1" },
  { label: "UA", value: "+380" },
  { label: "GB", value: "+44" },
  { label: "DE", value: "+49" },
  { label: "FR", value: "+33" },
  { label: "IT", value: "+39" },
  { label: "ES", value: "+34" },
  { label: "RU", value: "+7" },
  { label: "NL", value: "+31" },
  { label: "BE", value: "+32" },
  { label: "CH", value: "+41" },
  { label: "SE", value: "+46" },
  { label: "NO", value: "+47" },
  { label: "DK", value: "+45" },
  { label: "PL", value: "+48" },
  { label: "IE", value: "+353" },
  { label: "GR", value: "+30" },
  { label: "PT", value: "+351" },
  { label: "CZ", value: "+420" },
  { label: "HU", value: "+36" },
  { label: "FI", value: "+358" },
  { label: "AT", value: "+43" },
  { label: "BG", value: "+359" },
  { label: "RO", value: "+40" },
  { label: "SK", value: "+421" },
  { label: "SI", value: "+386" },
  { label: "HR", value: "+385" },
  { label: "BA", value: "+387" },
  { label: "ME", value: "+382" },
  { label: "MK", value: "+389" },
  { label: "AL", value: "+355" },
  { label: "LT", value: "+370" },
  { label: "EE", value: "+372" },
  { label: "LV", value: "+371" },
  { label: "CY", value: "+357" },
  { label: "LU", value: "+352" },
  { label: "MT", value: "+356" },
  { label: "IS", value: "+354" },
  { label: "MD", value: "+373" },
  { label: "AM", value: "+374" },
  { label: "MC", value: "+377" },
  { label: "SM", value: "+378" },
  { label: "VA", value: "+379" },
  { label: "RS", value: "+381" },
  { label: "BY", value: "+375" },
  { label: "AD", value: "+376" },
  { label: "LI", value: "+423" },
  { label: "GE", value: "+995" },
  { label: "AZ", value: "+994" },
  { label: "TR", value: "+90" },
];
