import dayjs from "dayjs";
import { TICK, TICKS_IN_HOUR, TICK_HEIGHT } from "./constants";
import { padZero } from "../../../../../utils/index.";

export function isInterim(n: number): boolean {
  return n === 0 || n % 2 !== 0 ? true : false;
}

export function getNewDates(offsetY: number, start: Date, end: Date) {
  const ticks = Math.round(offsetY / TICK_HEIGHT);
  const minutes = ticks * TICK;
  const newStart: Date = dayjs(start).add(minutes, "minutes").toDate();
  const newEnd: Date = dayjs(end).add(minutes, "minutes").toDate();
  return { start: newStart, end: newEnd };
}

export type Period = { start: Date; end: Date };
export function timePreview({ start, end }: Period, full = true): string {
  if (!full) {
    return `${padZero(start.getHours())}:${padZero(start.getMinutes())} `;
  }
  return `${padZero(start.getHours())}:${padZero(
    start.getMinutes()
  )} - ${padZero(end.getHours())}:${padZero(end.getMinutes())}`;
}

export function getTicksAmount({ start, end }: Period, rangeStart: number) {
  return {
    start:
      (start.getHours() - rangeStart) * TICKS_IN_HOUR +
      start.getMinutes() / TICK,
    end:
      (end.getHours() - rangeStart) * TICKS_IN_HOUR + end.getMinutes() / TICK,
  };
}

export function getPositions(range: [number, number], height: number) {
  const rangeDiff = range[1] - range[0];
  return {
    max: rangeDiff * TICKS_IN_HOUR * TICK_HEIGHT - height,
    min: 0,
  };
}

export function getProps(ticksToStart: number, ticksToEnd: number) {
  const [start, end] = [ticksToStart, ticksToEnd];
  const [topPos, bottomPos] = [start * TICK_HEIGHT, end * TICK_HEIGHT];
  const height = bottomPos - topPos;
  return {
    top: topPos,
    bottom: bottomPos,
    height,
  };
}

type Down = {
  scrollHeight: number;
  clientHeight: number;
  scrollTop: number;
};
export function canScrollDown({ scrollHeight, clientHeight, scrollTop }: Down) {
  return scrollHeight > scrollTop + clientHeight;
}

export function canScrollUp({ scrollTop }: { scrollTop: number }) {
  return scrollTop > 0;
}
