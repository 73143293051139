import { atom, useRecoilState } from "recoil";

import { useEffect } from "react";
import { axiosInstance } from "../api/axiosConfig";

type State<T> = {
  data: T | null;
  loading: boolean;
};

const sectionDataState = atom({
  key: "sectionData",
  default: {
    data: null,
    loading: false,
  },
});

type MutateFn<T> = (stateData: State<T>["data"]) => State<T>["data"] | void;

type SectionState<T> = {
  data: State<T>["data"];
  loading: boolean;
  fetch: () => void;
  mutate: (mutationFunction: MutateFn<T>, triggerLoading?: boolean) => void;
};

export function useSectionData<T, T_TR = T>(
  url?: string | null,
  transformFn?: (data: T) => T_TR
): SectionState<T> {
  const [{ data, loading }, setState] = useRecoilState(sectionDataState);

  const getData = async () => {
    if (!url) return;
    try {
      setState((prev) => ({ ...prev, data: null, loading: true }));
      const { data } = await axiosInstance.get<T>(url);
      //@ts-ignore
      setState((prev) => ({
        ...prev,
        data: transformFn ? transformFn(data) : data,
        loading: false,
      }));
    } catch (error) {}
  };

  const fetch = () => {
    getData();
  };

  const mutateData = (mutationFn: MutateFn<T>, triggerLoading?: boolean) => {
    //@ts-ignore
    setState((prev) => ({ ...prev, data: mutationFn(prev.data) || prev.data }));
    if (triggerLoading && !loading) {
      setState((prev) => ({ ...prev, loading: true }));
      setTimeout(() => {
        setState((prev) => ({ ...prev, loading: false }));
      }, 1);
    }
  };

  useEffect(() => {
    getData();
  }, [url]);

  return {
    data,
    loading,
    fetch,
    mutate: mutateData,
  };
}
