export const ROUTES = {
  main: "schedule",
  update: "schedule/update",
  create: "schedule/create",
};

export const URL = "appointments";

export const START_DATE_KEY = "startDateInit";

export const OFFERS_LIST_FIELD_KEY = "offerIds";
