import { FC, useState } from "react";
import { Snackbar, Alert, IconButton } from "@mui/material";
import {
  Close as CloseIcon,
  CheckCircle as CheckIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";

type ToastProps = {
  type: "success" | "warning" | "error";
  message: string;
  canDismissed: boolean;
};

export const Toast: FC<ToastProps> = ({ type, message, canDismissed }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={type}
        iconMapping={{
          success: <CheckIcon fontSize="inherit" />,
          warning: <WarningIcon fontSize="inherit" />,
          error: <ErrorIcon fontSize="inherit" />,
        }}
        action={
          canDismissed ? (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          ) : null
        }
        sx={{ mb: 2 }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
