import type { FC } from "react";
import type { Config } from "../types";
import { TableCell, TableHead } from "@mui/material";

interface Props {
  config: Config[];
  sortBy: string;
  sortDirection: "asc" | "desc";
  onSort: (key: string) => void;
}
const warning = (rowName: string) => {
  console.log(
    `Sorting by ${rowName} column will not work, please specify the key by which you want to sort. Add key or sortKey in table config`
  );
};
const Head: FC<Props> = ({ config, onSort, sortBy, sortDirection }) => {
  const addOnSort = (key: string) => () => onSort(key);

  return (
    <TableHead>
      {config.map(
        ({ key, title, width, sort, sortKey, colClasses = "" }, i) => {
          const mySortKey = sortKey ?? key;
          const isActiveSort = mySortKey === sortBy;
          const Icon = sortDirection === "asc" ? "ascI" : "descI";

          if (sort && !mySortKey) warning(title);

          return (
            <TableCell
              key={`${title}__${i}`}
              onClick={sort ? addOnSort(mySortKey) : undefined}
              style={{ padding: "15px 10px" }}
            >
              <div style={width ? { width: width } : undefined}>
                <span>{title}</span>
                {sort && "sort Icon"}
              </div>
            </TableCell>
          );
        }
      )}
    </TableHead>
  );
};

export default Head;
