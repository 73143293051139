import { atom } from "recoil";

export type User = {
  email: string;
  id: string;
  name: string;
  phoneNumber: string;
  roles: string[];
};

export const userData = atom<User | null>({
  key: "userData",
  default: null,
});
