import { NavigateFunction } from "react-router-dom";

interface NavigateWithParamsOptions {
  newPath: string;
  paramsToRemove?: string[];
}

export const navigateWithParams = (navigate: NavigateFunction, options: NavigateWithParamsOptions): void => {
  const { newPath, paramsToRemove = [] } = options;
  const [path, queryString] = newPath.split("?");
  const currentUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(currentUrl.search);

  paramsToRemove.forEach((param) => {
    searchParams.delete(param);
  });

  if (queryString) {
    const newParams = new URLSearchParams(queryString);
    newParams.forEach((value, key) => {
      searchParams.set(key, value);
    });
  }

  const newUrl = `${path}?${searchParams.toString()}`;
  navigate(newUrl);
};
