import * as React from "react";
import HomeIcon from "@mui/icons-material/Home";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import YardIcon from "@mui/icons-material/Yard";
import { NavButton } from "./NavButton";
import { useLocation } from "react-router-dom";
import { Calendar } from "../../../components/DateNavigation";
import { Box } from "@mui/material";

type Props = {
  open: boolean;
  toggleDrawer: React.MouseEventHandler<HTMLButtonElement>;
};

export const NavList: React.FC<Props> = ({ open, toggleDrawer }) => {
  const location = useLocation();

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Calendar previewMode={!open} toggleDrawer={toggleDrawer} />
      </Box>
      <NavButton to="/" active={location.pathname === "/"}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </NavButton>
      <NavButton to="/schedule" active={location.pathname === "/schedule"}>
        <ListItemIcon>
          <CalendarMonthIcon />
        </ListItemIcon>
        <ListItemText primary="Schedule" />
      </NavButton>
      <NavButton to="/services" active={location.pathname === "/services"}>
        <ListItemIcon>
          <YardIcon />
        </ListItemIcon>
        <ListItemText primary="Services" />
      </NavButton>
    </React.Fragment>
  );
};

export const NavList2 = null;
