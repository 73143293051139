import { FC } from "react";

import { PageFormModal } from "../../../layouts/PageFormModal";
import { useSearchParams } from "react-router-dom";
import { START_DATE_KEY } from "../constants";

import dayjs from "dayjs";

import { useAppState } from "../../../stores/appMain";
import { Button, Card, CardActions, CardContent, Divider, Typography } from "@mui/material";

type Props = {};

export const Warn: FC<Props> = () => {
  const [searchParams] = useSearchParams();
  const startDateStr = searchParams.get(START_DATE_KEY);

  const { setValue } = useAppState.selectedDate();

  const toCurrentDay = () => {
    setValue(dayjs().toDate());
  };

  return (
    <PageFormModal sx={{ height: "200px", width: "450px", maxWidth: "96%" }}>
      <Card variant="outlined" sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1" }}>
          <br />
          <Typography variant="h5">You cannot create an appointment for a past date.</Typography>
        </CardContent>
        <Divider />
        <CardActions sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
          <Typography gutterBottom sx={{ color: "text.secondary", fontSize: 14, textAlign: "left" }}>
            <div>
              <strong>Today: </strong> {dayjs().format("YYYY-MM-DD")}
            </div>
            <div>
              <strong>Selected: </strong>
              {dayjs(startDateStr).format("YYYY-MM-DD")}
            </div>
          </Typography>
          <Button size="small" onClick={toCurrentDay}>
            Go to current date --{">"}
          </Button>
        </CardActions>
      </Card>
    </PageFormModal>
  );
};
