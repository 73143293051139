import { FC } from "react";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField, Button, Box, Typography } from "@mui/material";
import { schema } from "./schema";
import { FormInput } from "../types";

type Props = {
  onSubmit: SubmitHandler<FormInput>;
};
const init: FormInput = {
  login: "",
};

export const Form: FC<Props> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: zodResolver(schema),
    defaultValues: init,
  });

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          maxWidth: "420px",
          marginTop: "100px",
        }}
      >
        <Typography
          component="h3"
          variant="h4"
          sx={{
            marginBottom: "20px",
          }}
        >
          Reset Password
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="login"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Phone number or Email"
                error={!!errors.login}
                margin="dense"
                helperText={errors.login?.message}
                sx={{
                  "& .MuiFormHelperText-root": {
                    marginX: 0,
                  },
                }}
                fullWidth
              />
            )}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{ marginTop: "20px" }}
            size="large"
            fullWidth
          >
            Get code
          </Button>
        </form>
      </Box>
    </Box>
  );
};
