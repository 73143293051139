import { Typography } from "@mui/material";
import { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const Title: FC<Props> = ({ children }) => {
  return (
    <Typography
      variant="subtitle2"
      component="div"
      sx={{
        fontSize: "10px",
        margin: 0,
        padding: 0,
        color: "#fff",
        paddingX: "10px",
        fontWeight: "800",
        display: "flex",
      }}
    >
      {children}
    </Typography>
  );
};
