import { atom } from "recoil";

export type Service = {
  id: number;
  title: string;
  price: number | null;
  duration: number | null; // in minutes
  isActive: boolean;
  category?: string; // do not exists yet
};
export const servicesData = atom<Service[]>({
  key: "servicesData",
  default: [],
});
