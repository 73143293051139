import { Outlet, useNavigate } from "react-router";
import { useContext, useEffect, type FC } from "react";
import { AuthContext } from "../context/AuthContext";
import { Box, CircularProgress, Typography } from "@mui/material";

const AuthCheck: FC = () => {
  const { userId, authInProgress } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authInProgress && !userId) {
      navigate("/auth/login");
    }
  }, [navigate, userId, authInProgress]);

  if (authInProgress)
    return (
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box sx={{ m: "auto", textAlign: "center" }}>
          <Typography variant="h5">Loading profile...</Typography>
          <br />
          <CircularProgress />
        </Box>
      </Box>
    );

  return userId ? <Outlet /> : null;
};

export default AuthCheck;
