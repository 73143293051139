import { z } from "zod";

const numberTransform = z.union([z.string(), z.number()]).transform((val) => (val ? Number(val) : -1));

export const schema = z.object({
  id: z.union([z.string(), z.number()]).optional(),
  title: z.string().min(1, "Service name is required"),
  price: numberTransform.refine((val) => val >= 0, {
    message: "Price must be greater than or equal to 0",
  }),
  duration: numberTransform.refine((val) => val >= 10, {
    message: "Duration must be greater than or equal to 10",
  }),
});
