import React, { createContext, useContext, ReactNode } from "react";
import {
  useForm,
  UseFormReturn,
  FormProvider,
  Resolver,
} from "react-hook-form";

interface FormContextProps {
  children: ReactNode;
  defaultValues?: any;
  resolver?: Resolver<any, any>;
}

const FormContext = createContext<UseFormReturn<any> | null>(null);

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error(
      "useFormContext must be used within a FormProviderComponent"
    );
  }
  return context;
};

export const FormProviderComponent: React.FC<FormContextProps> = ({
  children,
  defaultValues,
  resolver,
}) => {
  const methods = useForm({ defaultValues, resolver });

  return (
    <FormContext.Provider value={{ ...methods }}>
      <FormProvider {...methods}>{children}</FormProvider>
    </FormContext.Provider>
  );
};
