import { Box } from "@mui/system";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import { forwardRef } from "react";

type Props = {};

export const DraggableAreaBtn = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          background: "#23926654",
          borderRight: "1px solid #eee",
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          width: "30px",
          cursor: "grab",
          padding: "4px 2px",
        }}
      >
        <Box
          sx={{
            background: `
            radial-gradient(circle, rgba(13, 101, 28, 0.2) 2px, transparent 2px),
            radial-gradient(circle, rgba(13, 101, 28, 0.2) 2px, transparent 2px)
          `,
            backgroundSize: "6px 6px",
            borderRadius: "6px",
            width: "100%",
            height: "100%",
          }}
        />
        <BackHandOutlinedIcon
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: "5px",
            color: "#239266",
            fontSize: "18px",
          }}
        />
      </Box>
    );
  }
);
