import { FC, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Form } from "./Form";
import { FormInput } from "./types";
import { AuthRoot } from "../../../layouts/AuthRoot";
import { Button, Typography } from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";
import { STATUS_CODE_LOGOUT } from "../../../api/axiosConfig";

const init: FormInput = {
  login: "",
  password: "",
};

export const Login: FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const logoutUser = Boolean(queryParams.get(STATUS_CODE_LOGOUT));
  const { userId, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const { signIn } = useContext(AuthContext);

  const onSubmit = async (data: FormInput) => {
    const result = await signIn(data);
    if (result?.data?.id) navigate("/");
  };

  useEffect(() => {
    if (logoutUser) {
      navigate({ search: "" }, { replace: true });
      logout();
    } else if (userId) {
      navigate("/");
    }
  }, [logoutUser]);

  return (
    <AuthRoot
      actionBtn={
        <Button to="../registration" component={Link} variant="outlined">
          <Typography sx={{ textTransform: "none" }}>Create account</Typography>
        </Button>
      }
    >
      <Form onSubmit={onSubmit} init={init} />
    </AuthRoot>
  );
};
