import { FC, useEffect } from "react";

import { useWatch } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useFormContext } from "../../../../../components/MainForm";
import { DateField } from "../../../../../components/MainForm/inputs/DateField";
import { TimeField } from "../../../../../components/MainForm/inputs";

type Props = {};

const START_DATE = "startDate";
const END_DATE = "endDate";

export const DateTimePicker: FC<Props> = () => {
  const { setValue } = useFormContext();
  const startDateState: Date = useWatch({ name: START_DATE });
  const endDateState: Date = useWatch({ name: END_DATE });

  useEffect(() => {
    let startDate = dayjs(startDateState);
    let endDate = dayjs(endDateState);
    if (!startDate.isSame(endDate, "day")) {
      const endTime = endDate.format("HH:mm:ss");
      endDate = dayjs(`${startDate.format("YYYY-MM-DD")}T${endTime}`);
      setValue(END_DATE, endDate.toDate());
    }
  }, [startDateState]);

  return (
    <>
      <Typography component="div">Day and time:</Typography>
      <DateField name={START_DATE} size="small" label="Day" disablePast required />
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={1} rowSpacing={0}>
          <Grid item xs={6}>
            <TimeField name={START_DATE} size="small" label="Time start" ampm={false} required />
          </Grid>
          <Grid item xs={6}>
            <TimeField
              name={END_DATE}
              size="small"
              label="Time End"
              minTime={dayjs(startDateState)}
              ampm={false}
              required
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
