import { FC } from "react";

import { Box, Typography } from "@mui/material";
import { TextField } from "../../../../../components/MainForm/inputs";

type Props = {};

export const ClientInfo: FC<Props> = () => {
  return (
    <>
      <Typography component="div">Client info:</Typography>
      <Box>
        <TextField name="clientName" label="Name" size="small" required />
        <TextField name="clientPhone" label="Phone" size="small" required />
        <TextField name="clientEmail" label="Email" size="small" />
      </Box>
    </>
  );
};
