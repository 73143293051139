import { FC } from "react";

import { Controller, FieldErrors } from "react-hook-form";
import { FormControl, TextField, TextFieldProps } from "@mui/material";
import { useFormContext } from "../FormContext";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Message } from "./parts";
import { getValueByPath } from "../../../../utils/index.";

type Props = TextFieldProps & {
  name: string;
  label?: string;
  disablePast?: boolean;
  minDate?: Dayjs;
};

export const DateField: FC<Props> = ({ name, sx, minDate, disablePast, ...props }) => {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const error = getValueByPath<FieldErrors<any>>(errors, name);
  const helpText = error?.message || props.helperText || " ";
  const label = props.required ? `${props.label}*` : props.label;

  return (
    <FormControl fullWidth error={!!error} margin="none" sx={{ mt: "6px" }}>
      <Controller
        name={name}
        control={control}
        disabled={isSubmitting}
        render={({ field }) => (
          <DatePicker
            {...field}
            value={dayjs(field.value)}
            onChange={(newValue) => field.onChange(newValue?.toDate())}
            disablePast={disablePast}
            minDate={minDate}
            slots={{
              textField: (params) => (
                <TextField
                  {...params}
                  {...props}
                  error={!!error}
                  label={label}
                  required={false} // not use native browser required message
                  helperText={null}
                />
              ),
            }}
          />
        )}
      />
      <Message>{helpText}</Message>
    </FormControl>
  );
};
