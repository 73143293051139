import { FC } from "react";
import { Box, Typography } from "@mui/material";

import { OnboardingGrid } from "../../../components/OnboardingGrid";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../api/axiosConfig";
import { CreateSpecialistForm, CreateSpecialistFormInput } from "../../Specialists/Create";

export const CreateSpecialist: FC = () => {
  const navigate = useNavigate();

  const onSubmit = async (data: CreateSpecialistFormInput) => {
    const result = await axiosInstance.post("specialists", data);
    if (result?.data?.id) navigate("/");
  };
  return (
    <OnboardingGrid stepN={2}>
      <Box
        sx={{
          display: "flex",
          maxWidth: "460px",
          margin: "auto",
        }}
      >
        <Box>
          <Box>
            <Typography variant="h4" component="h4">
              Add an Specialist
            </Typography>
            <Typography variant="subtitle1">
              You have just created a service. Now add the specialist who will perform it. If you are working alone for
              now, just use your name <span style={{ opacity: "0.5" }}>You can edit or delete it at any time.</span>
            </Typography>
          </Box>
          <CreateSpecialistForm onSubmit={onSubmit} />
        </Box>
      </Box>
    </OnboardingGrid>
  );
};
