import { atomFamily, DefaultValue, selectorFamily, useRecoilState, type SerializableParam } from "recoil";
import queryString from "query-string";
import { useEffect, useRef } from "react";
import { axiosInstance } from "../api/axiosConfig";
import { cleanQueryParams } from "../../utils/index.";

type PaginationMeta = {
  total: number;
  perPage: number;
  currentPage: number;
  loading: boolean;
};

type SortDirection = "asc" | "desc";

const paginationDefaults = function (page: SerializableParam): string {
  switch (page as string) {
    case "xxx":
      return "xxx";
    default:
      return "id";
  }
};

export const searchFamily = atomFamily({
  key: "searchFamily",
  default: "",
});

export const pageFamily = atomFamily({
  key: "pageFamily",
  default: 1,
});

export const perPageFamily = atomFamily({
  key: "perPageFamily",
  default: 5,
});

export const sortByFamily = atomFamily({
  key: "sortByFamily",
  default: paginationDefaults,
});

export const sortDirectionFamily = atomFamily({
  key: "sortDirectionFamily",
  default: "asc" as SortDirection,
});

export const filtersFamily = atomFamily({
  key: "filtersFamily",
  default: (key: SerializableParam): Record<string, any> => {
    switch (key) {
      default:
        return {};
    }
  },
});

export const paginationQueryFamily = atomFamily({
  key: "paginationQueryFamily",
  default: selectorFamily({
    key: "paginationQueryFamily/Default",
    get:
      (param) =>
      ({ get }) => {
        const filters = get(filtersFamily(param));

        return {
          search: get(searchFamily(param)),
          page: get(pageFamily(param)),
          perPage: get(perPageFamily(param)),
          sortBy: get(sortByFamily(param)),
          sortDirection: get(sortDirectionFamily(param)),
          ...filters,
        };
      },
  }),
});

export const metaFamily = atomFamily({
  key: "metaFamily",
  default: {
    total: 0,
    perPage: 10,
    currentPage: 1,
    loading: false,
  } as PaginationMeta,
});

export const dataFamily = atomFamily({
  key: "dataFamily",
  default: [],
});

export const paginationFamily = atomFamily({
  key: "paginationFamily",
  default: selectorFamily({
    key: "paginationFamily/Default",
    get:
      (param) =>
      ({ get }) => {
        return {
          search: get(searchFamily(param)),
          page: get(pageFamily(param)),
          perPage: get(perPageFamily(param)),
          sortBy: get(sortByFamily(param)),
          sortDirection: get(sortDirectionFamily(param)),
          meta: get(metaFamily(param)),
          data: get(dataFamily(param)),
        };
      },
  }),
});

export const responseSelectorFamily = selectorFamily({
  key: "responseSelectorFamily",
  get:
    (param) =>
    ({ get }) => ({
      meta: get(metaFamily(param)),
      data: get(dataFamily(param)),
    }),
  set:
    (param) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        set(metaFamily(param), newValue);
        set(dataFamily(param), newValue);
        return;
      }

      set(metaFamily(param), newValue.meta);
      set(dataFamily(param), newValue.data);
    },
});

type PaginationQuery = {
  search?: string;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: "asc" | "desc";
  [key: string]: unknown; // filter
};

type Response<T> = {
  data: T[];
  meta: PaginationMeta;
};

export type MutateFn<T, M = T> = (stateData: T[]) => M[] | void;

type PaginationState<T> = {
  data: T[];
  meta: PaginationMeta;
  loading: boolean;
  fetch: () => void;
  mutate: <M>(mutationFunction: MutateFn<T, M>, triggerLoading?: boolean) => void;
};

export function usePaginatedData<T>(
  baseUrl: string,
  query?: PaginationQuery | null,
  isLazy?: boolean //isLazy on init request (onMount)
): PaginationState<T> {
  const urlPrev = useRef("");
  const url = `${baseUrl}?${queryString.stringify(cleanQueryParams(query || {}))}`;
  const familyKey = baseUrl;
  const [state, setState] = useRecoilState(responseSelectorFamily(familyKey));
  const loading = state.meta?.loading;

  const getData = async () => {
    urlPrev.current = url;
    try {
      setState((prev) => ({
        meta: { ...prev.meta, loading: true },
        data: [],
      }));
      const { data } = await axiosInstance.get<Response<T>>(url);
      if (typeof data?.meta === "object" && Array.isArray(data.data)) {
        setState(() => ({
          meta: { ...data.meta, loading: false },
          data: data.data as never[],
        }));
      } else {
        setState((prev) => ({
          meta: { ...prev.meta, loading: false },
          data: [],
        }));
      }
    } catch (error) {}
  };

  const fetch = () => getData();

  const mutateData = <M>(mutationFn: MutateFn<T, M>, triggerLoading?: boolean) => {
    setState((prev) => ({
      ...prev,
      data: (mutationFn(prev.data) || prev.data) as never[],
    }));

    if (triggerLoading && !loading) {
      setState((prev) => ({ ...prev, meta: { ...prev.meta, loading: true } }));
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          meta: { ...prev.meta, loading: false },
        }));
      }, 1);
    }
  };

  useEffect(() => {
    const isInitRequest = urlPrev.current === "";
    if (isInitRequest) {
      if (!isLazy) {
        urlPrev.current = url;
        getData();
        return;
      }
      urlPrev.current = url;
    } else if (urlPrev.current !== url) getData();
  }, [url]);

  return {
    data: state.data,
    meta: state.meta,
    loading,
    fetch,
    mutate: mutateData,
  };
}
