import { FC, ReactNode } from "react";

import { Box, SxProps, Theme, Typography } from "@mui/material";

type Props = {
  title: string;
  subtitle: string;
  children?: ReactNode;
  sx?: SxProps<Theme>;
};

export const Title: FC<Props> = ({ title, subtitle, children, sx }) => {
  return (
    <Box sx={{ lineHeight: 1, ...(sx || {}) }}>
      <Typography component="h6" variant="h6" sx={{ lineHeight: 1.2, fontSize: "16px" }}>
        {title}
      </Typography>
      <Typography component="h6" variant="h6" sx={{ lineHeight: 1.2, fontSize: "16px" }}>
        {subtitle}
      </Typography>
      {children}
    </Box>
  );
};
