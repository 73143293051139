import {
  useEffect,
  MouseEvent,
  TouchEvent,
  RefObject,
  MouseEventHandler,
} from "react";

type UseEventsProps = {
  dragging: boolean;
  onMouseMove: (event: MouseEvent<HTMLDivElement>) => void;
  onMouseUp: (event: MouseEvent<HTMLDivElement>) => void;
  onTouchMove: (event: TouchEvent<HTMLDivElement>) => void;
  onTouchEnd: (event: TouchEvent<HTMLDivElement>) => void;
};

export const useEvents = (props: UseEventsProps) => {
  const { dragging, onMouseMove, onMouseUp, onTouchMove, onTouchEnd } = props;
  return useEffect(() => {
    //@ts-ignore
    document.addEventListener("mouseup", onMouseUp);
    //@ts-ignore
    document.addEventListener("touchend", onTouchEnd, {
      passive: false,
    });
    if (dragging) {
      //@ts-ignore
      document.addEventListener("mousemove", onMouseMove);
      //@ts-ignore
      document.addEventListener("touchmove", onTouchMove, {
        passive: false,
      });
    }

    return () => {
      //@ts-ignore
      document.removeEventListener("mousemove", onMouseMove);
      //@ts-ignore
      document.removeEventListener("mouseup", onMouseUp);
      //@ts-ignore
      document.removeEventListener("touchmove", onTouchMove, {
        passive: false,
      });
      //@ts-ignore
      document.removeEventListener("touchend", onTouchEnd);
    };
  }, [dragging]);
};

type UseEventProps = {
  ref: RefObject<HTMLDivElement>;
  onTouchStart: (event: TouchEvent<HTMLDivElement>) => void;
  handleMouseDown: (event: MouseEvent<HTMLDivElement>) => void;
};

export const useEvent = (props: UseEventProps) => {
  const { ref, onTouchStart, handleMouseDown } = props;
  return useEffect(() => {
    //@ts-ignore
    ref.current.addEventListener("touchstart", onTouchStart, {
      passive: false,
    });
    //@ts-ignore
    ref.current.addEventListener("mousedown", handleMouseDown);

    return () => {
      //@ts-ignore
      ref.current?.removeEventListener?.("touchstart", onTouchStart, {
        passive: false,
      });
      //@ts-ignore
      ref.current?.removeEventListener?.("mousedown", handleMouseDown);
    };
  }, []);
};
