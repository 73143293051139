import type { FC } from "react";
import TableRow from "./TableRow";
import Placeholder from "./Placeholder";
import type { Config } from "../types";
import { TableBody } from "@mui/material";

interface Props {
  config: Config[];
  data: any[];
  isLoading: boolean;
}

const Body: FC<Props> = ({ config, data, isLoading }) => {
  return (
    <TableBody>
      {isLoading && <Placeholder />}
      {!isLoading &&
        data.map((item, index) => {
          return <TableRow config={config} key={index} data={item} />;
        })}
    </TableBody>
  );
};

export default Body;
