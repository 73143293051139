import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Form } from "./Form";
import { FormInput } from "./types";
import { AuthRoot } from "../../../layouts/AuthRoot";

export const ResetPassword: FC = () => {
  const navigate = useNavigate();
  const onSubmit = (data: FormInput) => {
    console.log("data", data);
    navigate("../onboarding/welcome");
  };
  return (
    <AuthRoot>
      <Form onSubmit={onSubmit} />
    </AuthRoot>
  );
};
