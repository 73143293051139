import { FC } from "react";
import { Box, Button, Divider, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFieldArray, useFormContext } from "react-hook-form";
import { SelectOption } from "../../../../../../types";
import { ErrorMessage, Select } from "../../../../../components/MainForm/inputs";

type Props = {
  options: SelectOption[];
  name: string;
  selectedServices: number[];
};

export const ServicesField: FC<Props> = ({ options, name, selectedServices }) => {
  const { fields, append, remove } = useFieldArray({ name });
  const {
    formState: { errors },
  } = useFormContext();

  const disableDel = selectedServices.length < 2;
  const error = selectedServices.length < 1 && (errors[name]?.message || errors[name]?.root?.message);

  return (
    <>
      {fields.map((field: any, index: number) => (
        <Box sx={{ display: "flex", marginBottom: "5px" }} key={field.id}>
          <Select
            name={`${name}.${index}`}
            options={options}
            size="small"
            FormControlProps={{ sx: { my: 0 } }}
            placeholder="Select service"
            hideHelpText
          />
          <IconButton aria-label="delete" size="medium" onClick={() => remove(index)} disabled={disableDel}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Box>
      ))}
      {error && (
        <Box
          sx={{
            ".MuiFormHelperText-root": {
              color: "#d32f2f",
            },
            ".MuiDivider-root": {
              background: "#d32f2f",
            },
          }}
        >
          <ErrorMessage>{error}</ErrorMessage>
          <Divider />
        </Box>
      )}
      <Box sx={{ display: "flex", marginTop: "10px" }}>
        <Button size="small" sx={{ margin: "0 40px 0 auto" }} variant="outlined" onClick={() => append(null)}>
          Add service +
        </Button>
      </Box>
    </>
  );
};
