import { convertStrToDates } from "./dateConvertor";
import { serializer } from "./serializer";

export { serializer, convertStrToDates };

export const generateTimeSlots = (startHour: number, endHour: number, intervalMinutes: number): string[] => {
  const times: string[] = [];
  for (let hour = startHour; hour < endHour; hour++) {
    for (let minute = 0; minute < 60; minute += intervalMinutes) {
      const time = `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
      times.push(time);
    }
  }
  return times;
};

export function toggleValue<T>(array: T[], value: T): T[] {
  const index = array.indexOf(value);
  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
  return array;
}

export function findById<T extends { id: K }, K>(array: T[], id: K): T | undefined {
  return array.find((obj) => obj.id === id);
}

export function findByIds<T extends { id: K }, K>(array: T[], ids: K[]): T[] {
  return array.filter((obj) => ids.includes(obj.id));
}

export function createArray<T>(length: number, value: T): T[] {
  return new Array<T>(length).fill(value);
}

export function sequentialArray(length: number, indexFrom?: number): number[] {
  return Array.from({ length }, (_, index) => (typeof indexFrom === "number" ? index + indexFrom : index));
}

export function padZero(input: string | number) {
  input = typeof input === "string" ? input : String(input);
  return input.padStart(2, "0");
}

export function getQueryParam(key: string): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(key);
  return value ? decodeURIComponent(value) : null;
}

export function setQueryParam(param: string, value = ""): void {
  const url = new URL(window.location.href);
  url.searchParams.set(param, value); // без encodeURIComponent
  window.history.pushState({}, "", url.toString());
}

interface QueryParams {
  [key: string]: unknown;
}

export function cleanQueryParams(query: QueryParams): QueryParams {
  return Object.fromEntries(Object.entries(query).filter(([_, value]) => value != null && value !== ""));
}

export function getValueByPath<T>(obj: any, path: string): T | undefined {
  const keys = path.split(".");
  let result = obj;

  for (const key of keys) {
    if (result && key in result) {
      result = result[key];
    } else {
      return undefined;
    }
  }

  return result;
}

export function groupByKey<T extends Record<string, unknown>>(array: T[] = [], key: string): T[][] {
  return Array.from(
    array
      .reduce((acc, obj) => {
        const keyValue = obj[key];
        if (!acc.has(keyValue)) {
          acc.set(keyValue, []);
        }
        acc.get(keyValue).push(obj);
        return acc;
      }, new Map())
      .values()
  );
}
