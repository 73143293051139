import { FC } from "react";

import { Controller, FieldErrors } from "react-hook-form";
import { FormControl, TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { useFormContext } from "../FormContext";
import { Message } from "./parts";
import { getValueByPath } from "../../../../utils/index.";

type Props = TextFieldProps & {
  name: string;
  label?: string;
  hidden?: boolean;
};

export const TextField: FC<Props> = ({ name, sx, hidden, ...props }) => {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const error = getValueByPath<FieldErrors<any>>(errors, name);
  const helpText = error?.message || props.helperText || " ";
  const label = props.required ? `${props.label}*` : props.label;

  if (hidden)
    return (
      <Controller
        name={name}
        control={control}
        disabled={isSubmitting}
        render={({ field }) => <input {...field} style={{ display: "none" }} />}
      />
    );

  return (
    <FormControl fullWidth error={!!error} margin="none" sx={{ mt: "6px" }}>
      <Controller
        name={name}
        control={control}
        disabled={isSubmitting}
        render={({ field }) => (
          <MuiTextField
            {...field}
            {...props}
            error={!!error}
            label={label}
            required={false} // not use native browser required message
            helperText={null}
          />
        )}
      />
      <Message>{helpText}</Message>
    </FormControl>
  );
};
