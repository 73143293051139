import { RouterProvider } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./App.css";
import { router } from "./router";
import { Box } from "@mui/material";
import { useMainState } from "./adminUI/stores/appMain";

function App() {
  const { state } = useMainState();
  console.log("state", state);
  return (
    <Box className="App">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </Box>
  );
}

export default App;
