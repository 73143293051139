import { FC, useRef } from "react";

import { Controller, FieldErrors } from "react-hook-form";
import { FormControl, Autocomplete, TextField, TextFieldProps, FormControlProps } from "@mui/material";
import { useFormContext } from "../FormContext";
import { SelectOption } from "../../../../types";
import { Message } from "./parts";
import { getValueByPath } from "../../../../utils/index.";

type Props = TextFieldProps & {
  name: string;
  options: SelectOption[];
  label?: string;
  hideHelpText?: boolean;
  FormControlProps?: FormControlProps;
  addEmptyOption?: boolean;
};

export const Select: FC<Props> = ({ name, sx, options: opt, FormControlProps, addEmptyOption, ...props }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = getValueByPath<FieldErrors<any>>(errors, name);
  const helpText = error?.message || props.helperText || " ";
  const label = props.required ? `${props.label}*` : props.label;
  const inputRef = useRef<HTMLInputElement>(null);

  const options = addEmptyOption ? [{ label: "None", value: undefined }, ...opt] : opt;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth error={!!error} margin="none" sx={{ my: "6px" }} {...FormControlProps}>
          <Autocomplete
            {...field}
            fullWidth
            value={options.find((item) => item.value === field.value) || undefined}
            selectOnFocus={false}
            onChange={(_, option) => {
              field.onChange(option?.value);
              props.onChange?.({
                target: {
                  //@ts-ignore
                  value: option?.value,
                },
              });
            }}
            options={options}
            getOptionLabel={(option) => option.label}
            sx={sx}
            disableClearable={true} // если нужно использовать свойство нужно что бы в момент очистки это влияло на react-hook-form
            renderInput={(field) => (
              <TextField
                {...field}
                {...props}
                inputRef={inputRef}
                error={!!error}
                label={label}
                required={false} // not use native browser required message
                helperText={null}
              />
            )}
          />
          {!props.hideHelpText && <Message>{helpText}</Message>}
        </FormControl>
      )}
    />
  );
};
