import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { FC } from "react";

export const LoadingCover: FC<{ open: boolean }> = ({ open }) => {
  if (!open) return null;
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backdropFilter: "blur(10px)", // This will blur the background
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
