import { FormHelperText } from "@mui/material";
import { FC, ReactNode } from "react";

export const Message: FC<{ children: ReactNode | string | any }> = (props) => {
  return (
    <FormHelperText sx={{ m: 0, textAlign: "right", lineHeight: "1.2" }}>
      {props.children}
    </FormHelperText>
  );
};
