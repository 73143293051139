import { FC, useRef } from "react";

import { Box, Typography } from "@mui/material";
import MainForm from "../../../../components/MainForm";
import { Button } from "@mui/material";

import { Select } from "../../../../components/MainForm/inputs";

type Props = {
  specialists: {
    label: string;
    value: number;
  }[];
  init?: Object;
  onSubmit: (value: Object) => void;
};

export const Filters: FC<Props> = ({ specialists, onSubmit, init }) => {
  const sbtBtn = useRef<HTMLButtonElement>(null);

  return (
    <Box sx={{ display: "flex" }}>
      <Typography>Filters:</Typography>
      <Box sx={{ ml: "10px", flex: "1" }}>
        <MainForm onSubmit={onSubmit} init={init}>
          <Select
            name="specialistId"
            label="Select specialist"
            options={specialists}
            onChange={() => sbtBtn.current?.click()}
            size="small"
            hideHelpText
            addEmptyOption
          />

          <Button ref={sbtBtn} type="submit" sx={{ display: "none" }}>
            Submit
          </Button>
        </MainForm>
      </Box>
    </Box>
  );
};
