import type { FC } from "react";
import type { Config } from "../types";
import { TableCell, TableRow as MuiTableRow } from "@mui/material";

export interface Props {
  data: Record<string, any>;
  config: Config[];
}

const isNA = (value: any): boolean => value === null || value === undefined;

const TableRow: FC<Props> = ({ config, data }) => {
  return (
    <MuiTableRow>
      {config.map(({ key, title, width, renderer, colClasses = "" }) => {
        let value = key ? data[key] : data;
        if (!renderer && !(typeof value === "string" || typeof value === "number" || !value)) {
          value = JSON.stringify(value);
        }
        return (
          <TableCell key={`${key}__${title}`} className={colClasses} style={{ padding: "15px 10px", width: width }}>
            {width ? (
              <div style={{ width: width }}>{renderer ? renderer(value) : isNA(value) ? "N/A" : value}</div>
            ) : (
              <>{renderer ? renderer(value) : isNA(value) ? "N/A" : value}</>
            )}
          </TableCell>
        );
      })}
    </MuiTableRow>
  );
};

export default TableRow;
