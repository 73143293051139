import { FC, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Toast } from "./Toast";

export const ToastHandler: FC = function () {
  const [toasts, setToasts] = useState<any[]>([]);

  useEffect(() => {
    const newToastListener = ({ detail }: any) => {
      const key = toasts.length + (Math.random() + 1) * 10;
      setToasts((prev) => [...prev, { props: detail.props, key }]);
      setTimeout(() => {
        setToasts((prev) => prev.filter((val) => val.key !== key));
      }, detail.timeout);
    };

    window.addEventListener("toast", newToastListener);

    return () => {
      window.removeEventListener("toast", newToastListener);
    };
  }, [toasts]);

  return createPortal(
    <>
      {toasts.map((toast) => (
        <Toast key={toast.key} {...toast.props} />
      ))}
    </>,
    document.getElementById("toast-container")!
  );
};
