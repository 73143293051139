import { FC } from "react";
import { FormProviderComponent, useFormContext } from "./FormContext";
import { ReactHookForm, UniversalFormProps } from "./ReactHookForm";
import { Resolver } from "react-hook-form";

export { useFormContext, FormProviderComponent };

type Props = UniversalFormProps & {
  init?: unknown;
  resolver?: Resolver<any, any>;
};

export const MainForm: FC<Props> = ({ onSubmit, children, init, resolver, sx }) => {
  return (
    <FormProviderComponent defaultValues={init} resolver={resolver}>
      <ReactHookForm sx={sx} onSubmit={onSubmit}>
        {children}
      </ReactHookForm>
    </FormProviderComponent>
  );
};

export default MainForm;
