import { FC, ReactNode } from "react";
import { Box, Typography } from "@mui/material";

import list from "./list.png";
import { MyStepper } from "../Stepper";

type Props = {
  stepN: number;
  children: ReactNode;
};

export const OnboardingGrid: FC<Props> = ({ stepN, children }) => {
  return (
    <Box sx={{ height: "100svh", display: "flex", flexDirection: "column" }}>
      <Box
        component="section"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: { xs: "10px", md: "15px" },
            background: "#f8fafc",
            flexDirection: "column",
            paddingTop: { md: "100px" },
            width: { md: "30%" },
          }}
        >
          <Box
            component="section"
            sx={{
              flex: "1",
              display: { xs: "none", sm: "none", md: "block" },
            }}
          >
            <img src={list} alt="List" style={{ width: "100%" }} />
          </Box>
          <Box sx={{ py: "10px" }}>
            <Typography
              variant="h6"
              component="h6"
              marginY={2}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              Progress...
            </Typography>
            <MyStepper steps={3} current={stepN} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: "10px", md: "15px" },
            flex: 1,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
