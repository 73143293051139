import { Box, SxProps, Theme } from "@mui/system";
import { useFormContext } from "./FormContext";
import { ReactNode } from "react";
import { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form";
import { SubmitError } from "../../api/axiosConfig";
import { FormInput } from "../../pages/Auth/SigningUp/types";

export type UniversalFormProps<T extends FieldValues = any> = {
  onSubmit: SubmitHandler<T> | ((data: T) => Promise<unknown>);
  children: ReactNode | ((context: UseFormReturn<T>) => ReactNode);
  sx?: SxProps<Theme>;
};

export const ReactHookForm: React.FC<UniversalFormProps> = ({
  onSubmit,
  children,
  sx,
}) => {
  const methods = useFormContext();

  const onSubmitCatch = async (data: unknown) => {
    try {
      await onSubmit(data);
    } catch (error) {
      //Catching axios errors when submitting a form
      const _error = error as SubmitError;
      const backendErrors =
        //@ts-ignore
        (_error.response?.data?.errors as {
          [key: string]: unknown;
        }) || {};

      for (const key in backendErrors) {
        if (backendErrors.hasOwnProperty(key)) {
          methods.setError(key as keyof FormInput, {
            type: "manual",
            message: String(backendErrors[key]),
          });
        }
      }
    }
  };
  return (
    <Box
      sx={sx}
      component="form"
      onSubmit={methods.handleSubmit(onSubmitCatch)}
    >
      {typeof children === "function" ? children(methods) : children}
    </Box>
  );
};
