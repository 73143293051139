import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { OnboardingGrid } from "../../../components/OnboardingGrid";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../api/axiosConfig";
import { CreateServiceForm, CreateServiceFormInput } from "../../Services";

export const CreateService: FC = () => {
  const navigate = useNavigate();

  const onSubmit = async (data: CreateServiceFormInput) => {
    const result = await axiosInstance.post("offers", data);
    if (result?.data?.id) navigate("/onboarding/specialists/create");
  };

  return (
    <OnboardingGrid stepN={1}>
      <Box
        sx={{
          display: "flex",
          maxWidth: "460px",
          margin: "auto",
        }}
      >
        <Box>
          <Box>
            <Typography variant="h4" component="h4">
              Create a Service
            </Typography>
            <Typography variant="subtitle1">
              Let's create your first service.{" "}
              <Typography style={{ opacity: "0.5" }}>You can edit or delete it at any time.</Typography>
            </Typography>
          </Box>
          <CreateServiceForm onSubmit={onSubmit} hideTitle />
        </Box>
      </Box>
    </OnboardingGrid>
  );
};
