import { atom } from "recoil";

export type Company = {
  address: string;
  description: string;
  id: string;
  isActive: boolean;
  logoUrl: string;
  name: string;
};

export const companyData = atom<Company | null>({
  key: "companyData",
  default: null,
});
