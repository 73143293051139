import { RefObject } from "react";
import { Direction } from "./types";

export function createCursorDirectionTracker(
  offset: number = 10
): (currentCursorY: number) => Direction {
  let lastCursorY: number | null = null;
  let difference: number = 0;
  let currentDirection: Direction = Direction.none;

  return function getCursorDirection(currentCursorY: number): Direction {
    if (lastCursorY === null) {
      lastCursorY = currentCursorY;
      return currentDirection;
    }

    const position = currentCursorY - lastCursorY;
    difference = difference + position;
    lastCursorY = currentCursorY;

    if (difference > offset) {
      if (currentDirection !== Direction.bottom) {
        difference = 0;
        currentDirection = Direction.bottom;
      }
    } else if (difference < -offset) {
      if (currentDirection !== Direction.top) {
        difference = 0;
        currentDirection = Direction.top;
      }
    }

    if (Math.abs(difference) > offset + 1) difference = 0;
    return currentDirection;
  };
}
type Visibility = {
  element: RefObject<HTMLDivElement>;
  containerId: string;
  offset?: number;
};
export function checkVisibility(props: Visibility) {
  const { offset: o, element, containerId } = props;
  const offset = o || 0;
  const container = document.getElementById(containerId) as HTMLDivElement;
  const contRect = container.getBoundingClientRect();
  const elemRect = element.current?.getBoundingClientRect() as DOMRect;

  return {
    top: elemRect.top >= contRect.top + offset,
    bottom: elemRect.bottom <= contRect.bottom - offset,
  };
}
