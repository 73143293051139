import { FC, ReactNode } from "react";
import { ListItemButton } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {
  children: ReactNode;
  to: string;
  active: boolean;
};

export const NavButton: FC<Props> = ({ to, children, active }) => {
  return (
    <ListItemButton
      to={to}
      component={Link}
      selected={active}
      sx={{
        ...(active && {
          cursor: "default",
          "& svg": {
            color: "#1876d2",
          },
          "& *": {
            fontWeight: "600",
            color: "#1876d2",
          },
        }),
      }}
    >
      {children}
    </ListItemButton>
  );
};
