import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { SxProps, Theme, Typography, TypographyProps } from "@mui/material";

type Props = { children: ReactNode } & TypographyProps;

//For this component to work correctly, the parent component must have the maximum possible height
const TruncatedText: FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [lineCount, setLineCount] = useState(1);

  const updateLineCount = () => {
    if (ref.current && ref.current.parentElement) {
      const lineHeight =
        parseInt(getComputedStyle(ref.current).lineHeight, 10) || 16;
      const parentStyle = getComputedStyle(ref.current.parentElement);
      const paddingTop = parseInt(parentStyle.paddingTop, 10) || 0;
      const paddingBottom = parseInt(parentStyle.paddingBottom, 10) || 0;

      const containerHeight =
        ref.current.parentElement.clientHeight - paddingTop - paddingBottom;
      const visibleLines = Math.floor(containerHeight / lineHeight);
      setLineCount(visibleLines);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateLineCount);
    updateLineCount();

    return () => {
      window.removeEventListener("resize", updateLineCount);
    };
  }, []);

  const textStyle: SxProps<Theme> = {
    ...props.sx,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: lineCount,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  };

  return <Typography {...props} ref={ref} sx={textStyle} />;
};

export default TruncatedText;
