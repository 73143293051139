import { z } from "zod";
import { OFFERS_LIST_FIELD_KEY } from "../../constants";

export const schema = z
  .object({
    id: z.union([z.string(), z.number()]).optional(),
    specialistId: z.number({ invalid_type_error: "Specialist required" }),
    clientName: z.string().min(1, { message: "String must be at least 1 character long" }),
    clientPhone: z.string().min(1, { message: "String must be at least 1 character long" }),
    clientEmail: z.string().nullable().optional(),
    [OFFERS_LIST_FIELD_KEY]: z
      .array(z.number({ invalid_type_error: "Select service" }).min(1, { message: "Select at least one service" }))
      .nonempty({ message: "Add at least one service" }),
    startDate: z.date(),
    endDate: z.date(),
  })
  .refine((data) => data.startDate.getTime() < data.endDate.getTime(), {
    message: "Must be later than start",
    path: ["endDate"],
  });
