import MuiDrawer from "@mui/material/Drawer";
import { drawerWidth } from "../constants";
import { Divider, IconButton, List, Toolbar } from "@mui/material";
import { NavList } from "./NavList";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { FC, MouseEventHandler } from "react";

type Props = {
  open: boolean;
  toggleDrawer: MouseEventHandler<HTMLButtonElement>;
  isMob: boolean;
};

export const Drawer: FC<Props> = ({ open, toggleDrawer, isMob }) => {
  const width = isMob ? drawerWidth.mob : drawerWidth.desk;

  return (
    <>
      {isMob && <div style={{ width: "55px" }} />}
      <MuiDrawer
        variant="permanent"
        open={open}
        sx={(theme) => ({
          "& .MuiDrawer-paper": {
            position: isMob ? "absolute" : "relative",
            boxShadow: isMob && open ? "0px 3px 10px rgba(0,0,0,0.3)" : "",
            whiteSpace: "nowrap",
            width,
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            ...(!open && {
              overflowX: "hidden",
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              width: theme.spacing(7),
              [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
              },
            }),
            "& .MuiListItemIcon-root": {
              minWidth: isMob ? "40px" : "55px",
            },
          },
        })}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav" sx={{ p: 0 }}>
          <NavList open={open} toggleDrawer={toggleDrawer} />
        </List>
      </MuiDrawer>
    </>
  );
};
