import { IconButton, SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import ModalMui from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import { FC, ReactNode } from "react";
import { navigateWithParams } from "../hooks/navigateWithParams";
import { useNavigate } from "react-router-dom";

type Props = {
  children: ReactNode;
  onClose?: Function;
  sx?: SxProps<Theme>;
};

export const PageFormModal: FC<Props> = ({ children, sx, ...props }) => {
  const navigate = useNavigate();
  const toMain = () =>
    navigateWithParams(navigate, {
      newPath: "../",
      paramsToRemove: [],
    });

  const onClose = props.onClose || toMain;

  return (
    <ModalMui open={true} onClose={() => onClose()}>
      <Box
        sx={{
          borderRadius: "6px",
          outline: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "420px",
          maxWidth: "96%",
          maxHeight: "98%",
          bgcolor: "background.paper",
          overflowX: "hidden",
          padding: "15px",
          ...(sx || {}),
        }}
      >
        <IconButton
          aria-label="delete"
          sx={{ position: "absolute", right: "0", top: "0" }}
          onClick={() => onClose()}
          size="medium"
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </ModalMui>
  );
};
