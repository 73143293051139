import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { FormInput } from "../types";
import MainForm from "../../../../components/MainForm";
import {
  Checkbox,
  PasswordField,
  TextField,
} from "../../../../components/MainForm/inputs";
import { zodResolver } from "@hookform/resolvers/zod";
import { schema } from "./schema";
import { SubmitBtn } from "../../../../components/MainForm/SubmitBtn";
import { PhoneNumber } from "../../../../components/MainForm/inputs/PhoneNumber";

type Props = {
  onSubmit: (values: FormInput) => Promise<unknown>;
  init?: FormInput;
};

export const Form: FC<Props> = ({ onSubmit, init }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{ maxWidth: "420px", marginTop: "100px" }}>
        <Typography component="h3" variant="h4" sx={{ marginBottom: "20px" }}>
          Registration
        </Typography>
        <MainForm
          onSubmit={onSubmit}
          resolver={zodResolver(schema)}
          init={init}
        >
          <PhoneNumber />
          <TextField name="email" label="Email" />
          <PasswordField name="password" label="Password" />
          <Checkbox name="acceptPolicy" label="I accept the policy" />
          <SubmitBtn
            size="large"
            variant="contained"
            sx={{ marginTop: "20px" }}
          />
        </MainForm>
      </Box>
    </Box>
  );
};
