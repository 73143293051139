import { createTableCnf } from "../../../components/Table";
import { updateLinkRenderer } from "../../../components/Table/renderers";

export const getColumns = () => {
  return createTableCnf([
    {
      key: "id",
      title: "ID",
    },
    {
      key: "title",
      title: "Title",
    },
    {
      key: "price",
      title: "Price",
    },
    {
      key: "duration",
      title: "Duration",
    },
    {
      key: "",
      title: "Edit",
      width: "40px",
      renderer: updateLinkRenderer,
    },
  ]);
};
