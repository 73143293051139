import { FC, ReactNode } from "react";

import { Button } from "@mui/material";

type Props = {
  children: ReactNode;
};

export const SubmitBtn: FC<Props> = ({ children }) => {
  return (
    <Button
      type="submit"
      variant="contained"
      sx={{ marginTop: "10px" }}
      size="small"
      fullWidth
    >
      {children}
    </Button>
  );
};
