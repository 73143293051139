import { createContext, useEffect, FC, useState } from "react";
import Cookies from "js-cookie";
import { TOKEN_KEY, axiosInstance } from "../api/axiosConfig";
import { Outlet } from "react-router-dom";
import { AxiosResponse } from "axios";
import {
  Company,
  User,
  useMainState,
  Specialist,
  Service,
  MAIN_STATE_MAP,
} from "../stores/appMain";

type Profile = User & {
  [MAIN_STATE_MAP.COMPANY_DATA_KEY]: Company & {
    [MAIN_STATE_MAP.SPECIALIST_DATA_KEY]: Specialist[];
    [MAIN_STATE_MAP.SERVICES]: Service[];
  };
};

type Login = Profile & {
  access_token?: string;
};

type Credentials = {
  login: string;
  password: string;
};

type Result = Promise<AxiosResponse<any, any> | undefined>;

type UserDto = any;
interface AuthContextType {
  userId: User["id"] | null;
  signIn: (data: Credentials) => Result;
  signUp: (data: UserDto) => Result;
  logout: () => void;
  authInProgress: boolean;
}

export const AuthContext = createContext<AuthContextType>({
  userId: null,
  signIn: async () => undefined,
  signUp: async () => undefined,
  logout: async () => undefined,
  authInProgress: true,
});

export const AuthProvider: FC = () => {
  const { state, setState, resetState } = useMainState();
  const [authInProgress, setAuthInProgress] = useState(true);

  const updateMainState = (data: Profile | null) => {
    if (!data?.id) {
      resetState();
    } else {
      const {
        [MAIN_STATE_MAP.COMPANY_DATA_KEY]: {
          [MAIN_STATE_MAP.SPECIALIST_DATA_KEY]: specialists,
          [MAIN_STATE_MAP.SERVICES]: services,
          ...companyRest
        },
        ...user
      } = data;

      setState((prev) => ({
        ...prev,
        [MAIN_STATE_MAP.LOADING_KEY]: false,
        [MAIN_STATE_MAP.USER_DATA_KEY]: user,
        [MAIN_STATE_MAP.COMPANY_DATA_KEY]: companyRest,
        [MAIN_STATE_MAP.SPECIALIST_DATA_KEY]: specialists || [],
        [MAIN_STATE_MAP.SERVICES]: services || [],
      }));
    }

    setAuthInProgress(false);
  };

  const fetchProfile = async () => {
    try {
      const response = await axiosInstance.get<Profile>("auth/profile");
      updateMainState(response.data);
    } catch (error) {
      logout();
      console.error("Failed to fetch profile", error);
    }
  };

  const signIn = async (credentials: { login: string; password: string }) => {
    const response = await axiosInstance.post<Login>(
      "auth/sign-in",
      credentials
    );
    if (response.data?.access_token) {
      Cookies.set(TOKEN_KEY, response.data.access_token, {
        secure: process.env.REACT_APP_NODE_ENV === "DEVELOPMENT" ? false : true,
        sameSite: "Strict",
        path: "/",
      });
      delete response.data.access_token;
      updateMainState({ ...response.data });
    }

    return response;
  };

  const signUp = async (userInfo: User) => {
    const response = await axiosInstance.post<Login>("auth/sign-up", userInfo);
    if (response.data?.access_token) {
      Cookies.set(TOKEN_KEY, response.data.access_token, {
        secure: true,
        sameSite: "Strict",
      });
      delete response.data.access_token;
      updateMainState({ ...response.data });
    }
    return response;
  };

  const logout = () => {
    Cookies.remove(TOKEN_KEY);
    updateMainState(null);
  };

  useEffect(() => {
    if (Cookies.get(TOKEN_KEY)) fetchProfile();
    else logout();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userId: state[MAIN_STATE_MAP.USER_DATA_KEY]?.id || null,
        signIn,
        signUp,
        logout,
        authInProgress,
      }}
    >
      <Outlet />
    </AuthContext.Provider>
  );
};
