import { FC, useState } from "react";
import { DayRange, ScrollDayFn } from "../../types";
import { Box } from "@mui/material";
import { Event } from "./Event";
import { ScheduleEvent } from "../../../../../../types";

type Props = {
  data: ScheduleEvent[];
  dayRange: DayRange;
  scrollDay: ScrollDayFn;
  onEventClick: (id: number, values: ScheduleEvent) => void;
  onSave: (values: ScheduleEvent) => Promise<boolean>;
  onChange: (values: ScheduleEvent) => void;
};

type ScheduleEventCustom = ScheduleEvent & { __isDirty?: boolean };

export const Events: FC<Props> = ({ data: originEvents, dayRange, scrollDay, onEventClick, onSave, onChange }) => {
  const [events, updateEvent] = useState<ScheduleEventCustom[]>(originEvents);

  const onUpdate = (data: ScheduleEvent, isDirty = true) => {
    updateEvent((prev) =>
      prev.map((obj) => {
        if (obj.id === data.id) {
          onChange({ ...data });
          return { ...data, __isDirty: isDirty }; //__isDirty item not saved on BE
        }
        return obj;
      })
    );
  };

  const onSaveProcess = async (data: ScheduleEvent): Promise<boolean> => {
    const result = await onSave(data);
    onUpdate(data, !result);
    return result;
  };

  const addOnClick = (id: number, data: ScheduleEventCustom) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onEventClick(id, data);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        height: "0",
        overflow: "visible",
        "& *": {
          userSelect: "none",
          WebkitUserSelect: "none" /* Chrome, Safari, Opera */,
          MozUserSelect: "none" /* Firefox */,
          msUserSelect: "none" /* Internet Explorer/Edge */,
        },
      }}
    >
      {events.map((item) => (
        <Event
          key={item.id}
          notSaved={!!item.__isDirty}
          data={item}
          range={dayRange}
          onUpdate={onUpdate}
          scrollDay={scrollDay}
          onClick={addOnClick(item.id, item)}
          onSave={onSaveProcess}
        />
      ))}
    </Box>
  );
};
