import { useRef, useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Outlet } from "react-router-dom";
import { AppBar } from "./parts/AppBar";
import { Drawer } from "./parts/Drawer";
import { theme } from "../../theme";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { CssBaseline } from "@mui/material";
import { SectionHeightContext } from "./context";
import { useAppState } from "../../stores/appMain";

export const AppBarNavBar = () => {
  const { value: loading } = useAppState.loading();
  const width = useWindowWidth();
  const isMob = width < 900;
  const [open, setOpen] = useState(isMob ? false : true);
  const sectionRef = useRef<HTMLDivElement>(null);
  const [sectionHeight, setSectionHeight] = useState(0);

  useEffect(() => {
    if (isMob && open) setOpen(false);
    if (!isMob && !open) setOpen(true);
    if (sectionRef.current) {
      const style = window.getComputedStyle(sectionRef.current);
      const paddingTop = parseFloat(style.paddingTop);
      const paddingBottom = parseFloat(style.paddingBottom);
      const actualHeight = sectionRef.current.offsetHeight - (paddingTop + paddingBottom) - 4;
      setSectionHeight(actualHeight);
    }
  }, [width]);

  const toggleDrawer = () => setOpen((prev) => !prev);

  return (
    <ThemeProvider theme={theme}>
      <SectionHeightContext.Provider value={sectionHeight}>
        <Box sx={{ display: "flex", position: "relative" }}>
          <CssBaseline />
          <AppBar toggleDrawer={toggleDrawer} isMob={isMob} loading={loading} />
          <Drawer open={open} toggleDrawer={toggleDrawer} isMob={isMob} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
              flexGrow: 1,
              height: "100svh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Box
              ref={sectionRef}
              component="section"
              id="scroll"
              sx={{
                padding: {
                  xs: "5px 10px 5px 5px",
                  md: "15px",
                },
                height: {
                  xs: "calc(100svh - 56px)",
                  md: "calc(100svh - 64px)",
                },
                overflowX: "hidden",
                overflowY: "scroll",
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Box>
      </SectionHeightContext.Provider>
    </ThemeProvider>
  );
};
