import { Box, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { DaySchedule } from "../parts/DaySchedule";
import dayjs from "dayjs";
import { ScheduleEvent, ScheduleEventApi } from "../../../types";
import { convertStrToDates, dateToStr } from "../../../../utils/dateConvertor";
import { useNavigate } from "react-router-dom";
import { TickPeriodTime } from "../parts/DaySchedule/parts/TickPeriod";
import { URL, ROUTES, START_DATE_KEY } from "../constants";
import { dispatchToast } from "../../../components/Toast";
import { axiosInstance } from "../../../api/axiosConfig";
import { useAppState } from "../../../stores/appMain";
import { navigateWithParams } from "../../../hooks/navigateWithParams";
import { filtersFamily, usePaginatedData } from "../../../stores/pagination";
import { Filters } from "../parts/Filters";
import { useRecoilValue, useSetRecoilState } from "recoil";

export const Main: FC = () => {
  const navigate = useNavigate();
  const { value: selectedDate } = useAppState.selectedDate();
  const { value: specialists } = useAppState.specialists();
  const oneSpecialistMode = specialists.length === 1;
  const specialistIdInit = specialists.length === 1 ? specialists[0]?.id : undefined;
  const setFilters = useSetRecoilState(filtersFamily(URL));
  const filters = useRecoilValue(filtersFamily(URL));
  const { loading, data, mutate } = usePaginatedData<ScheduleEventApi>(URL, filters, true);
  const specialistsOptions = specialists.map(({ id: value, name: label }) => ({ value, label }));

  const openUpdate = (id: number) => {
    navigateWithParams(navigate, { newPath: `/${ROUTES.update}/${id}` });
  };

  const openCreate = (values: TickPeriodTime) => {
    const dateStr = dayjs(selectedDate)
      .startOf("day")
      .add(Number(values.hours), "hour")
      .add(Number(values.minutes), "minute");

    navigateWithParams(navigate, {
      newPath: `/${ROUTES.create}?${START_DATE_KEY}=${dateToStr(dateStr)}`,
    });
  };

  const onEventSubmit = async (values: ScheduleEvent): Promise<boolean> => {
    if (!values.id) return false;
    try {
      const { data } = await axiosInstance.patch<ScheduleEventApi>(`${URL}/${values.id}`, {
        ...values,
        specialistId: filters.specialistId,
      });
      dispatchToast({ message: "Successfully updated." });

      mutate((list) => {
        return list?.map((item) => {
          if (item.id === data?.id) return data;
          return item;
        });
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const syncSectionState = (data: ScheduleEvent) => {
    mutate((list) => {
      return list?.map((item) => {
        if (item.id !== data?.id) return item;
        return {
          ...data,
          startDate: dateToStr(data.startDate)!,
          endDate: dateToStr(data.endDate)!,
        };
      });
    });
  };

  useEffect(() => {
    const specialistId = Object.keys(filters).includes("specialistId") ? filters.specialistId : specialistIdInit; //  if filter was init
    setFilters((prev) => ({
      ...prev,
      startDate: dateToStr(dayjs(selectedDate).startOf("day")),
      endDate: dateToStr(dayjs(selectedDate).endOf("day")),
      specialistId,
    }));
  }, [selectedDate]);

  if (specialists.length < 1) {
    // TODO show form for adding a specialist
    return (
      <Box sx={{ p: "20px" }}>
        <Typography variant="h6">Add at least one specialist in you company</Typography>
      </Box>
    );
  }

  return (
    <Box>
      {!oneSpecialistMode && (
        <Filters
          specialists={specialistsOptions}
          onSubmit={(values: Object) => setFilters((prev) => ({ ...prev, ...values }))}
          init={{ specialistIds: specialistIdInit }}
        />
      )}

      <DaySchedule
        events={convertStrToDates(data || [], ["startDate", "endDate"]) || []}
        dayRange={[9, 23]}
        onHourClick={openCreate}
        onEventClick={openUpdate}
        onEventSubmit={onEventSubmit}
        onEventChange={syncSectionState}
        loading={loading}
      />
    </Box>
  );
};
