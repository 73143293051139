import { Box, Divider, Paper } from "@mui/material";
import { FC, useContext, useRef, useState } from "react";
import { Hour } from "./parts/Hour";
import { Events } from "./parts/Events";
import { CONTAINER_ID } from "./constants";
import { canScrollDown as canDown, canScrollUp as canUp } from "./utils";
import { MobContextInit, RevertOnMobContext } from "./context";
import { SectionHeightContext } from "../../../../layouts/AppBarNavBar/context";
import { groupByKey, sequentialArray } from "../../../../../utils/index.";
import { DayRange, ScrollDayFn, TickFn } from "./types";
import { TickPeriodTime } from "./parts/TickPeriod";
import { ScheduleEvent } from "../../../../types";
import { LoadingCover } from "../../../../components/Loading";

type Props = {
  events: ScheduleEvent[];
  dayRange: DayRange;
  onHourClick: (time: TickPeriodTime) => void;
  onEventClick: (id: number, values: ScheduleEvent) => void;
  onEventSubmit: (values: ScheduleEvent) => Promise<boolean>;
  loading: boolean;
  onEventChange: (values: ScheduleEvent) => void;
};

export const DaySchedule: FC<Props> = ({
  events,
  dayRange,
  onHourClick,
  onEventClick,
  onEventSubmit,
  loading,
  onEventChange,
}) => {
  const sectionHeight = useContext(SectionHeightContext);
  const [revertOnMob, setRevertOnMob] = useState(MobContextInit);
  const ref = useRef<HTMLDivElement>({} as HTMLDivElement);
  const intervalId = useRef<number>(0);
  const dayHours = dayRange[1] - dayRange[0];
  const rangeHours = sequentialArray(dayHours, dayRange[0]);
  const groupedEvents = groupByKey(events, "specialistId");

  const handleScroll: ScrollDayFn = (tickOffset: number, onTick?: TickFn, deltaInit = 0) => {
    if (intervalId.current) cancelAnimationFrame(intervalId.current);
    let delta = deltaInit;
    const isDown = tickOffset > 0;
    function run() {
      const { scrollHeight, clientHeight, scrollTop: top } = ref.current;
      const scrollTop = top + tickOffset;
      intervalId.current = requestAnimationFrame(() => {
        const canScroll = isDown ? canDown({ scrollHeight, clientHeight, scrollTop }) : canUp({ scrollTop });
        if (!canScroll || onTick?.(delta) === false) return cancelAnimationFrame(intervalId.current);
        delta += tickOffset;
        ref.current.scrollTop = scrollTop;
        run();
      });
    }
    run();
  };

  return (
    <Paper
      id={CONTAINER_ID}
      ref={ref}
      sx={{
        borderRadius: "4px",
        background: "#fff",
        padding: {
          xs: "10px 10px",
          md: "20px 15px",
        },
        height: sectionHeight,
        position: "relative",
        overflow: "auto",
        scrollBehavior: "unset",
      }}
    >
      <RevertOnMobContext.Provider value={revertOnMob}>
        <Box
          id="day"
          sx={{
            position: "relative",
          }}
        >
          <Box component="ul" sx={{ padding: 0, margin: 0 }}>
            {rangeHours.map((item) => (
              <Hour key={item} data={item} isAutoScrolling={!!intervalId} onClick={onHourClick} />
            ))}
          </Box>
          {!loading && (
            <Box
              sx={{
                position: "absolute",
                left: {
                  xs: "40px",
                  md: "45px",
                },
                top: 0,
                bottom: 0,
                right: 0,
                height: 0,
                display: "flex",
              }}
            >
              {groupedEvents.map((events: ScheduleEvent[], i) => {
                return (
                  <Box
                    sx={{
                      position: "relative",
                      top: 0,
                      left: 0,
                      flex: 1,
                      pr: "4px",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                      }}
                    >
                      <Events
                        key={i}
                        data={events}
                        dayRange={dayRange}
                        scrollDay={handleScroll}
                        onEventClick={onEventClick}
                        onSave={onEventSubmit}
                        onChange={onEventChange}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </RevertOnMobContext.Provider>
      <LoadingCover open={loading} />
    </Paper>
  );
};
