import { FC } from "react";
import { URL } from "../constants";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../api/axiosConfig";
import { PageFormModal } from "../../../layouts/PageFormModal";
import { dispatchToast } from "../../../components/Toast";
import { usePaginatedData } from "../../../stores/pagination";
import { Form } from "../parts/Form";
import { FormInput } from "../types";
import { Service } from "../../../types";

export const Update: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: list, mutate } = usePaginatedData<Service>(URL, null, true);
  const storedItem = list?.find((item) => item.id === +id!);

  const onSubmit = async (values: FormInput) => {
    const { data } = await axiosInstance.patch(`${URL}/${values.id}`, values);
    if (data?.id) {
      mutate((values) => {
        return values?.map((item) => {
          if (item.id === data?.id) return { ...data };
          return item;
        });
      }, true);
      dispatchToast({ message: "Successfully updated." });
    }
  };

  return (
    <PageFormModal>
      <Form
        init={storedItem}
        onSubmit={onSubmit}
        sx={{
          width: "420px",
          maxWidth: "100%",
          marginX: "auto",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        isUpdate
      />
    </PageFormModal>
  );
};
