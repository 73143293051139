import { Box, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { Header } from "./Header";

type Props = {
  children: ReactNode;
  actionBtn?: ReactNode;
};

export const AuthRoot: FC<Props> = ({ children, actionBtn }) => {
  return (
    <Box sx={{ height: "100svh", display: "flex", flexDirection: "column" }}>
      <Box component="header" sx={{ background: "#eee" }}>
        <Box sx={{ paddingX: "20px" }}>
          <Header actionBtn={actionBtn} />
        </Box>
      </Box>
      <Box component="section" sx={{ flex: 1 }}>
        <Box sx={{ paddingX: "20px" }}>{children}</Box>
      </Box>
      <Box component="footer" sx={{ background: "#eee" }}>
        <Box sx={{ paddingX: "20px" }}>
          <Typography>About book-me</Typography>
        </Box>
      </Box>
    </Box>
  );
};
