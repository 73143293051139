import { type ReactNode, type FC, useEffect } from "react";
import MuiTable from "@mui/material/Table";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import THead from "./Head";
import TBody from "./Body";
import type { Config } from "../types";
import {
  pageFamily,
  paginationFamily,
  paginationQueryFamily,
  sortByFamily,
  sortDirectionFamily,
  usePaginatedData,
} from "../../../stores/pagination";
import { Box, Button, Pagination, PaginationItem, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";

interface Props {
  config: Config[];
  section: string;
  title: string;
  children?: ReactNode;
  canCreate?: boolean;
}

const Table: FC<Props> = ({ title, config, section, children, canCreate }) => {
  const paginationProps = useRecoilValue(paginationQueryFamily(section));
  const { loading } = usePaginatedData(section, paginationProps);
  const pagination = useRecoilValue(paginationFamily(section));
  const setPage = useSetRecoilState(pageFamily(section));
  const setSortBy = useSetRecoilState(sortByFamily(section));
  const setSortDirection = useSetRecoilState(sortDirectionFamily(section));
  const sortDirectionCurrent = pagination.sortDirection;
  const sortByCurrentKey = pagination.sortBy;

  const resetPage = useResetRecoilState(pageFamily(section));
  const resetSortBy = useResetRecoilState(sortByFamily(section));
  const resetDirection = useResetRecoilState(sortDirectionFamily(section));

  useEffect(() => {
    return () => {
      resetPage();
      resetSortBy();
      resetDirection();
    };
  }, []);

  const onSort = (sortByKey: string) => {
    if (sortByCurrentKey === sortByKey) {
      setSortDirection(sortDirectionCurrent === "asc" ? "desc" : "asc");
    } else setSortBy(sortByKey);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", pt: "10px" }}>
        {title && <Typography variant="h5">{title}</Typography>}

        {canCreate && (
          <Button to="create" component={Link} variant="outlined" sx={{ ml: "auto", mr: "0" }}>
            Create
          </Button>
        )}
      </Box>
      {children && <Box>{children}</Box>}
      <Box
        sx={{
          flex: 1,
          mr: "-15px",
          pr: "15px",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <MuiTable>
          <THead config={config} onSort={onSort} sortBy={pagination.sortBy} sortDirection={pagination.sortDirection} />
          <TBody config={config} data={pagination.data} isLoading={loading} />
        </MuiTable>
      </Box>
      <Box sx={{ display: "flex", mt: "10px" }}>
        <Pagination
          sx={{ ml: "auto", mr: "0" }}
          count={Math.ceil(pagination.meta.total / pagination.perPage)}
          page={pagination.page}
          onChange={(_, n) => setPage(n)}
          renderItem={(item) => (
            <PaginationItem slots={{ previous: ArrowBackIosIcon, next: ArrowForwardIosIcon }} {...item} />
          )}
        />
      </Box>
    </Box>
  );
};

export default Table;
