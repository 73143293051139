import { FC } from "react";

import { SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box } from "@mui/material";

import { schema } from "../schema";
import { FormInput } from "../types";
import { WeekdaysSelector } from "./parts/WeekdaysSelector";
import MainForm from "../../../../components/MainForm";
import { TextField } from "../../../../components/MainForm/inputs";
import { SubmitBtn } from "../../../../components/MainForm/SubmitBtn";

type Props = {
  onSubmit: SubmitHandler<FormInput>;
};

export const Form: FC<Props> = ({ onSubmit }) => {
  return (
    <Box
      sx={{
        marginTop: "10px",
      }}
    >
      <MainForm
        onSubmit={onSubmit}
        resolver={zodResolver(schema)}
        init={{
          schedule: ["mon", "tue", "wed", "thu", "fri"],
        }}
      >
        {({ control }) => {
          return (
            <>
              <TextField
                name="name"
                label="Name"
                helperText="For example: Alona Malynovska"
                required
              />
              <TextField
                name="specialization"
                label="Specialization"
                helperText="For example: Beauty master"
                required
              />

              <Controller
                name="schedule"
                control={control}
                render={({ field }) => (
                  <WeekdaysSelector
                    onChange={field.onChange}
                    value={field.value}
                  />
                )}
              />

              <SubmitBtn
                size="large"
                variant="contained"
                sx={{ marginTop: "20px" }}
              >
                Submit
              </SubmitBtn>
            </>
          );
        }}
      </MainForm>
    </Box>
  );
};
