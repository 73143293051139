import dayjs from "dayjs";
import { getQueryParam } from "../../../utils/index.";
import { atom } from "recoil";
import { MAIN_STATE_MAP } from "./constants";

export const selectedDate = atom<Date>({
  key: "selectedDate",
  default: dayjs(
    getQueryParam(MAIN_STATE_MAP.SELECTED_DATE_KEY) || undefined
  ).toDate(),
});
