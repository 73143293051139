import { z } from "zod";

const requiredMessage = "Required";

const phoneRegex = /^\+?[0-9]{3}-?[0-9]{6,12}$/;
const phoneSchema = z
  .string()
  .min(1, { message: requiredMessage })
  .regex(phoneRegex, { message: "Invalid phone number format" });

export const schema = z.object({
  phoneCode: z.string(),
  phone: phoneSchema,
  email: z
    .string()
    .min(1, { message: requiredMessage })
    .email({ message: "Invalid email address" }),
  password: z.string().min(5, { message: "Min 5 symbols" }),
  acceptPolicy: z
    .boolean()
    .refine((val) => val, { message: "You have to accept the policy" }),
});
