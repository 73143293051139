import { Box } from "@mui/material";
import { FC } from "react";
import { sequentialArray } from "../../../../../../utils/index.";
import { TickPeriod, TickPeriodTime } from "./TickPeriod";
import { TICKS_IN_HOUR, TICK_HEIGHT } from "../constants";

type Props = {
  data: number;
  isAutoScrolling: boolean;
  onClick?: (time: TickPeriodTime) => void;
};

const dividedHour = sequentialArray(TICKS_IN_HOUR);

export const Hour: FC<Props> = (props) => {
  const { data, isAutoScrolling: isScrolling, onClick } = props;

  const addOnTickClick = (time: TickPeriodTime) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick?.(time);
  };

  return (
    <Box
      component="li"
      data-period-box="hour-1"
      sx={{
        display: "flex",
        height: TICK_HEIGHT * TICKS_IN_HOUR,
      }}
    >
      <Box
        sx={{
          width: {
            xs: "20px",
            md: "25px",
          },
          marginRight: "0",
        }}
      >
        {data}
      </Box>
      <Box
        component="ul"
        sx={{
          borderTop: "1px solid #8f8e8e",
          flex: 1,
          padding: 0,
          margin: 0,
        }}
      >
        {dividedHour.map((item) => {
          const minutes = item * 10;
          const time = {
            hours: data,
            minutes: minutes,
          };
          return (
            <TickPeriod
              key={`${time.hours}_${time.minutes}`}
              n={item}
              time={time}
              disabled={isScrolling}
              onClick={addOnTickClick(time)}
            />
          );
        })}
      </Box>
    </Box>
  );
};
