import { FC } from "react";

import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { countryCodes } from "./types";

type Props = {
  // name: string TODO update phone input to smg better
};
export const PhoneNumber: FC<Props> = (props) => {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();
  return (
    <Controller
      name="phone"
      control={control}
      render={({ field }) => (
        <>
          <Box sx={{ display: "flex" }}>
            <Controller
              name="phoneCode"
              control={control}
              disabled={isSubmitting}
              render={({ field }) => (
                <FormControl
                  variant="outlined"
                  sx={{ width: "150px", marginRight: "5px" }}
                >
                  <InputLabel id="country-code-select-label">Code</InputLabel>
                  <Select
                    {...field}
                    labelId="country-code-select-label"
                    label="Code"
                  >
                    {countryCodes.map((item) => (
                      <MenuItem
                        key={`${item.label}-${item.value}`}
                        value={item.value}
                      >
                        {item.label} ({item.value})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <FormControl
              error={!!errors.phone}
              disabled={isSubmitting}
              fullWidth
              sx={{ flexDirection: "row" }}
            >
              <InputLabel htmlFor="component-phone">Mobile Phone</InputLabel>
              <OutlinedInput
                id="component-phone"
                aria-describedby="component-phone-text"
                type="number"
                label="Mobile Phone"
                fullWidth
                {...field}
              />
            </FormControl>
          </Box>
          <FormControl error={!!errors.phone} sx={{ width: " 100%" }}>
            <FormHelperText
              id="component-phone-text"
              sx={{ m: 0, textAlign: "right" }}
            >
              {/* @ts-ignore */}
              {errors.phone?.message ||
                "A confirmation code will be sent to this number"}
            </FormHelperText>
          </FormControl>
        </>
      )}
    />
  );
};
