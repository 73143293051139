import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {
  FC,
  useRef,
  useState,
  TouchEvent,
  MouseEvent,
  useEffect,
  useCallback,
  MouseEventHandler,
} from "react";
import { ScrollDayFn } from "../../types";
import { Title } from "../Title";
import TruncatedText from "../../../../../../components/TruncatedText";
import { LoadingDots } from "../../../../../../components/LoadingDots";
import {
  getPositions,
  getNewDates,
  getTicksAmount,
  timePreview,
  getProps,
} from "../../utils";
import { CONTAINER_ID as containerId, TICK } from "../../constants";
import { Direction } from "./types";
import { checkVisibility, createCursorDirectionTracker } from "./utils";
import { stylesWrap, stylesBox } from "./styles";
import { useEvent, useEvents } from "./useEvents";
import { DraggableAreaBtn } from "./DraggableAreaBtn";
import { ScheduleEvent } from "../../../../../../types";
import { LoadingButton } from "@mui/lab";

type Props = {
  data: ScheduleEvent;
  range: [number, number];
  onUpdate: (data: ScheduleEvent) => void;
  scrollDay: ScrollDayFn;
  notSaved: boolean;
  onSave: (values: ScheduleEvent) => Promise<boolean>;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
};

export const Event: FC<Props> = (props) => {
  const { data, range, onUpdate, scrollDay, onClick, notSaved, onSave } = props;
  const element = useRef<HTMLDivElement>(null);
  const dragBtn = useRef<HTMLDivElement>(null);
  const autoScrollDirection = useRef<Direction>(Direction.none);
  const scrollDelta = useRef(0);
  const [dragging, setDragging] = useState(false);
  const [cursorInit, setCursorInit] = useState(0);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [loading, setLoading] = useState(false);

  const { startDate, endDate } = data;
  const period = { start: startDate, end: endDate };
  const { start, end } = getTicksAmount(period, range[0]);
  const { top: topPos, height } = getProps(start, end);
  const top = topPos + cursorPosition;

  const getCursorDirection = useCallback(createCursorDirectionTracker(5), []);

  useEffect(() => {
    //submit action
    if (dragging) return;
    scrollDelta.current = 0;
    const { start, end } = getNewDates(cursorPosition, startDate, endDate);
    setCursorInit(0);
    setCursorPosition(0);
    if (startDate.getTime() === start.getTime()) return;
    onUpdate({ ...data, startDate: start, endDate: end });
  }, [dragging]);

  const autoScrollStop = () => (autoScrollDirection.current = Direction.none);

  const autoScrollStart = (scrollDirection: Direction) => {
    autoScrollDirection.current = scrollDirection;
    const ticKOffset = scrollDirection * 5;
    scrollDay(
      ticKOffset,
      (delta) => {
        if (!autoScrollDirection.current) return false;
        scrollDelta.current = delta;
        setCursorPosition((prev) => prev + ticKOffset);
        return true;
      },
      scrollDelta.current
    );
  };

  const move = (clientY: number) => {
    const newDirection = getCursorDirection(clientY);
    if (autoScrollDirection.current) {
      if (newDirection === autoScrollDirection.current) return;
      else {
        console.log("STOP autoScroll");
        autoScrollStop();
      }
    }
    if (dragging) {
      const { min, max } = getPositions(range, height);
      const transformY = clientY - cursorInit;
      const newPosition = topPos + transformY;
      if (newPosition <= min || newPosition >= max) return;
      const isEnoughShift = Math.abs(transformY) > TICK * 1.5;
      const { top, bottom } = checkVisibility({
        offset: TICK * 2,
        element,
        containerId,
      });
      if (newDirection === Direction.top && isEnoughShift && !top) {
        autoScrollStart(Direction.top);
      } else if (
        newDirection === Direction.bottom &&
        isEnoughShift &&
        !bottom
      ) {
        autoScrollStart(Direction.bottom);
      } else {
        const pos = transformY + scrollDelta.current;
        setCursorPosition(pos);
      }
    }
  };

  const handleStart = (clientY: number) => {
    setDragging(true);
    setCursorInit(clientY - cursorPosition);
  };

  const handleEnd = () => {
    setDragging(false);
    autoScrollStop();
  };

  const handleMouseDown = (event: MouseEvent) => handleStart(event.clientY);
  const onMouseMove = (event: MouseEvent) => move(event.clientY);
  const onMouseUp = () => handleEnd();

  const onTouchStart = (event: TouchEvent) => {
    event.preventDefault(); // Prevents scrolling on the touchscreen
    handleStart(event.touches[0].clientY);
  };
  const onTouchMove = (event: TouchEvent) => {
    event.preventDefault(); // Prevents scrolling on the touchscreen
    move(event.touches[0].clientY);
  };
  const onTouchEnd = () => handleEnd();

  useEvents({ dragging, onMouseMove, onMouseUp, onTouchMove, onTouchEnd });
  useEvent({ ref: dragBtn, onTouchStart, handleMouseDown });

  const saveItem: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    onSave(data).finally(() => setLoading(false));
  };
  return (
    <Paper ref={element} sx={stylesWrap({ top, height, dragging })}>
      <DraggableAreaBtn ref={dragBtn} />
      {/* @ts-ignore */}
      <Box
        onClick={onClick}
        sx={{ height: "100%", marginLeft: "30px", position: "relative" }}
      >
        <Typography sx={{ background: "#44bf8e", fontSize: 14 }}>
          <Title>
            {dragging ? (
              <>
                {timePreview(getNewDates(cursorPosition, startDate, endDate))}
              </>
            ) : (
              <>{timePreview(period)}</>
            )}
          </Title>
          {dragging && (
            <Box sx={stylesBox}>
              <LoadingDots />
            </Box>
          )}
        </Typography>
        <Box sx={{ padding: "4px 10px", flex: 1 }}>
          <Typography sx={{ textDecoration: "underline" }}>
            Client: {data.clientName} {data.clientPhone}
          </Typography>
          <TruncatedText sx={{ lineHeight: 1.2 }}>
            {data.description}
          </TruncatedText>
        </Box>
        {notSaved && (
          <Card
            sx={{
              background: "#9df9d5",
              position: "absolute",
              right: 5,
              bottom: 5,
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                display: {
                  xs: "block",
                  sm: "none",
                  background: "green",
                },
              }}
            >
              <IconButton
                onClick={saveItem}
                disabled={loading}
                sx={{ padding: "5px" }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#fff" }} size={18} />
                ) : (
                  <SaveIcon sx={{ fontSize: "18px", color: "#fff" }} />
                )}
              </IconButton>
            </Box>
            <LoadingButton
              size="small"
              onClick={saveItem}
              loading={loading}
              loadingPosition="end"
              endIcon={<SaveIcon />}
              variant="contained"
              color="success"
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                },
              }}
            >
              <span>Save </span>
            </LoadingButton>
          </Card>
        )}
      </Box>
    </Paper>
  );
};
