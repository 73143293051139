import { FC, MouseEventHandler, useContext } from "react";
import MuiAppBar from "@mui/material/AppBar";
import { drawerWidth } from "../constants";
import { Box, IconButton, LinearProgress, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthContext } from "../../../context/AuthContext";
import LogoutIcon from "@mui/icons-material/Logout";

type Props = {
  toggleDrawer: MouseEventHandler<HTMLButtonElement>;
  isMob: boolean;
  move?: boolean;
  loading?: boolean;
};

export const AppBar: FC<Props> = ({ move, toggleDrawer, isMob, loading }) => {
  const { logout } = useContext(AuthContext);
  const width = isMob ? drawerWidth.mob : drawerWidth.desk;

  return (
    <MuiAppBar
      position="absolute"
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(move && {
          marginLeft: width,
          width: `calc(100% - ${width}px)`,
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      })}
    >
      {loading && (
        <LinearProgress
          sx={{
            position: "absolute",
            left: 0,
            bottom: 0,
            right: 0,
          }}
        />
      )}

      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: "10px",
            display: move ? "none" : "inline-flex",
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }}>Book-me</Box>
        <IconButton color="inherit" onClick={logout}>
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </MuiAppBar>
  );
};
