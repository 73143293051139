import { FC } from "react";

import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { OnboardingGrid } from "../../../components/OnboardingGrid";

export const Welcome: FC = () => {
  return (
    <OnboardingGrid stepN={0}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}></Box>
      <Box
        sx={{
          display: "flex",
          maxWidth: "460px",
          margin: "auto",
        }}
      >
        <Box>
          <Typography variant="h4" component="h4">
            Welcome to Book-me!
            <Typography variant="subtitle1">Thank you for choosing us!</Typography>
          </Typography>
          <br />
          <Typography paragraph lineHeight={1.2}>
            Let's set up a profile to get the first customers. It is very easy!
            <Typography variant="subtitle2" sx={{ opacity: "0.5", mt: "5px" }}>
              Setup takes less than 2 minutes
            </Typography>
          </Typography>
          <br />
          <Button to="../services/create" component={Link} variant="contained" size="large" fullWidth>
            Set up
          </Button>
        </Box>
      </Box>
    </OnboardingGrid>
  );
};
