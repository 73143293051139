import { FC, useEffect, useRef } from "react";

import { Box, Typography } from "@mui/material";
import { ServicesField } from "./ServicesField";
import { SelectOptionService } from "../../../../../../types";
import { useFormContext } from "../../../../../components/MainForm";
import { Service } from "../../../../../types";
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import { OFFERS_LIST_FIELD_KEY } from "../../../constants";

dayjs.extend(durationPlugin);

type Props = {
  options: SelectOptionService[];
};

const renderDuration = (duration: number) => {
  const h = Math.floor(duration / 60);
  const m = duration % 60;
  return `${h}h. ${m}min.`;
};
const renderPeriod = (startDate: Date, minutes: number) => {
  const endDate = dayjs(startDate).add(minutes, "minute");
  return `${dayjs(startDate).format("HH:mm")} - ${dayjs(endDate).format("HH:mm")}`;
};

const getDurationDiff = (startDate: Date, endDate: Date) => {
  const dayjsDate1 = dayjs(endDate);
  const dayjsDate2 = dayjs(startDate);
  const diffInMilliseconds = dayjsDate1.diff(dayjsDate2);

  const diffDuration = dayjs.duration(diffInMilliseconds);

  return Math.floor(diffDuration.asMinutes());
};

export const Services: FC<Props> = ({ options }) => {
  const { watch, setValue } = useFormContext();
  const selectedServices: number[] = watch(OFFERS_LIST_FIELD_KEY) || [];
  const [startDate, endDate] = watch(["startDate", "endDate"]);
  const data: Service[] = [];

  selectedServices.forEach((item) => {
    const fined = options.find((option) => option.value === item);
    if (fined) data.push(fined.data);
  });

  const price = data.reduce((sum, item) => sum + Number(item.price), 0);
  const durationBySelected = data.reduce((sum, item) => sum + Number(item.duration), 0);
  const prevDuration = useRef(durationBySelected);

  const durationByDates = getDurationDiff(startDate, endDate);
  const diff = durationBySelected - durationByDates;

  useEffect(() => {
    if (prevDuration.current === durationBySelected) return;
    prevDuration.current = durationBySelected;
    if (diff !== 0) {
      const dayJsDate = dayjs(endDate).add(diff, "minute");
      setValue("endDate", dayJsDate.toDate());
    }
  }, [durationBySelected]);

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>Services:</Typography>
            <Typography
              component="span"
              fontSize={13}
              sx={{
                color: diff === 0 ? "green" : diff < 0 ? "#e18600" : "red",
                textShadow: ".4px .4px 0 #c3c3c3, -.4px -.4px 0 #c3c3c3, -.4px .4px 0 #c3c3c3, .4px -.4px 0 #c3c3c3",
                ml: "5px",
              }}
            >
              {renderDuration(durationBySelected)} {renderPeriod(startDate, durationBySelected)}
            </Typography>
          </Box>
          <Typography component="span">{price > 0 ? price : "- -"} $</Typography>
        </Box>
        <ServicesField options={options} name={OFFERS_LIST_FIELD_KEY} selectedServices={selectedServices} />
      </Box>
    </>
  );
};
