import { SxProps, Theme } from "@mui/material";

type Props = {
  top: number;
  height: number;
  dragging: boolean;
};
export const stylesWrap = ({ top, height, dragging }: Props) => {
  const s: SxProps<Theme> = {
    borderRadius: "6px",
    background: "#9df9d5",
    position: "absolute",
    width: "100%",
    top,
    height,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    zIndex: dragging ? 10 : undefined,
    cursor: "pointer",
  };
  return s;
};

export const stylesBox: SxProps<Theme> = {
  position: "absolute",
  top: "-4px",
  right: "10px",
};
