import {
  Resetter,
  SetterOrUpdater,
  selector,
  useRecoilState,
  useResetRecoilState,
} from "recoil";
import { getQueryParam, setQueryParam } from "../../../utils/index.";
import { useEffect } from "react";
import { dateToStr } from "../../../utils/dateConvertor";
import { selectedDate } from "./selectedDate";
import { User, userData } from "./userData";
import { Company, companyData } from "./companyData";
import { Specialist, specialistData } from "./specialistData";
import { Service, servicesData } from "./servicesData";
import { loadingData } from "./loadingData";
import { MAIN_STATE_MAP } from "./constants";

export type AppMainState = {
  [MAIN_STATE_MAP.SELECTED_DATE_KEY]: Date;
  [MAIN_STATE_MAP.USER_DATA_KEY]: User | null;
  [MAIN_STATE_MAP.COMPANY_DATA_KEY]: Company | null;
  [MAIN_STATE_MAP.SPECIALIST_DATA_KEY]: Specialist[];
  [MAIN_STATE_MAP.SERVICES]: Service[];
  [MAIN_STATE_MAP.LOADING_KEY]: boolean;
};

export const mainState = selector<AppMainState>({
  key: "mainState",
  get: ({ get }) => ({
    [MAIN_STATE_MAP.SELECTED_DATE_KEY]: get(selectedDate),
    [MAIN_STATE_MAP.USER_DATA_KEY]: get(userData),
    [MAIN_STATE_MAP.COMPANY_DATA_KEY]: get(companyData),
    [MAIN_STATE_MAP.SPECIALIST_DATA_KEY]: get(specialistData),
    [MAIN_STATE_MAP.SERVICES]: get(servicesData),
    [MAIN_STATE_MAP.LOADING_KEY]: get(loadingData),
  }),
  set: ({ set }, newValue) => {
    const newState = newValue as AppMainState;
    set(selectedDate, newState[MAIN_STATE_MAP.SELECTED_DATE_KEY]);
    set(userData, newState[MAIN_STATE_MAP.USER_DATA_KEY]);
    set(companyData, newState[MAIN_STATE_MAP.COMPANY_DATA_KEY]);
    set(specialistData, newState[MAIN_STATE_MAP.SPECIALIST_DATA_KEY]);
    set(servicesData, newState[MAIN_STATE_MAP.SERVICES]);
    set(loadingData, newState[MAIN_STATE_MAP.LOADING_KEY]);
  },
});

type UseMainState = {
  state: AppMainState;
  setState: SetterOrUpdater<AppMainState>;
  resetState: Resetter;
};

export function useMainState(): UseMainState {
  const [state, setState] = useRecoilState(mainState);
  const resetState = useResetRecoilState(mainState);

  useEffect(() => {
    if (
      dateToStr(state[MAIN_STATE_MAP.SELECTED_DATE_KEY]) !==
      getQueryParam(MAIN_STATE_MAP.SELECTED_DATE_KEY)
    ) {
      setQueryParam(
        MAIN_STATE_MAP.SELECTED_DATE_KEY,
        dateToStr(state[MAIN_STATE_MAP.SELECTED_DATE_KEY])
      );
    }
  }, [state[MAIN_STATE_MAP.SELECTED_DATE_KEY]]);
  return { state, setState, resetState };
}

export const useSelectedDate = () => {
  const [value, setValue] = useRecoilState(selectedDate);
  return { value, setValue };
};

export const useLoading = () => {
  const [value, setValue] = useRecoilState(loadingData);
  return { value, setValue };
};

export const useServicesData = () => {
  const [value, setValue] = useRecoilState(servicesData);
  return { value, setValue };
};

export const useSpecialistsData = () => {
  const [value, setValue] = useRecoilState(specialistData);
  return { value, setValue };
};

export const useAppState = {
  selectedDate: useSelectedDate,
  loading: useLoading,
  services: useServicesData,
  specialists: useSpecialistsData,
};

export { MAIN_STATE_MAP };
export type { Specialist, User, Service, Company };
