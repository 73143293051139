import { FC } from "react";

import { FormInput } from "../types";
import { URL } from "../constants";

import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../api/axiosConfig";
import { PageFormModal } from "../../../layouts/PageFormModal";
import { dispatchToast } from "../../../components/Toast";
import { usePaginatedData } from "../../../stores/pagination";
import { Form } from "../parts/Form";

export const Create: FC = () => {
  const navigate = useNavigate();
  const { mutate: mutateList } = usePaginatedData(URL, null, true);

  const onSubmit = async (values: FormInput) => {
    const { data } = await axiosInstance.post(URL, values);
    if (data?.id) {
      dispatchToast({ message: "Successfully created." });
      mutateList((list) => (list ? [data, ...list] : [data]), true);
      navigate("../");
    }
  };

  return (
    <PageFormModal>
      <Form
        onSubmit={onSubmit}
        sx={{
          width: "420px",
          maxWidth: "100%",
          marginX: "auto",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      />
    </PageFormModal>
  );
};
