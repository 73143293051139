import { FC } from "react";

import { Controller, FieldErrors } from "react-hook-form";
import { Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormHelperText, CheckboxProps } from "@mui/material";
import { useFormContext } from "../FormContext";
import { getValueByPath } from "../../../../utils/index.";

type Props = CheckboxProps & {
  name: string;
  label?: string;
};

export const Checkbox: FC<Props> = ({ name, label, sx, ...props }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = getValueByPath<FieldErrors<any>>(errors, name);
  const helpText = (error?.message || " ") as React.ReactNode;

  return (
    <FormControl fullWidth error={!!error} sx={{ py: 0 }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <MuiCheckbox
                sx={{
                  "&.MuiButtonBase-root": {
                    py: "5px",
                  },
                }}
                {...field}
                checked={field.value}
              />
            }
            label={label}
          />
        )}
      />
      {<FormHelperText sx={{ m: 0 }}>{helpText}</FormHelperText>}
    </FormControl>
  );
};
