import { FC } from "react";

import { SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, InputAdornment, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/system";

import { DurationPicker } from "./parts/DurationPicker";
import MainForm from "../../../../components/MainForm";
import { SubmitBtn } from "../../../../components/MainForm/SubmitBtn";
import { TextField } from "../../../../components/MainForm/inputs";
import { schema } from "./schema";
import { FormInput } from "../../types";

type Props = {
  onSubmit: SubmitHandler<FormInput>;
  init?: unknown;
  isUpdate?: boolean;
  sx?: SxProps<Theme>;
  hideTitle?: boolean;
};

export const Form: FC<Props> = ({ onSubmit, isUpdate, sx, hideTitle, init }) => {
  const initial = init || { duration: "60" };
  const title = isUpdate ? "Update" : "Create";

  return (
    <MainForm onSubmit={onSubmit} resolver={zodResolver(schema)} init={initial} sx={sx}>
      {!hideTitle && (
        <Typography variant="h5" component="h5">
          {title} Service
        </Typography>
      )}
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <TextField name="id" hidden />
        <TextField
          name="title"
          label="Service name"
          helperText="For example: Manicure with gel polish"
          InputProps={{
            endAdornment: <InputAdornment position="end">$</InputAdornment>,
          }}
          required
        />
        <TextField
          name="price"
          label="Service price"
          InputProps={{
            endAdornment: <InputAdornment position="end">$</InputAdornment>,
          }}
          type="number"
          required
        />
        <DurationPicker name="duration" />
      </Box>
      <Box>
        <SubmitBtn size="large" variant="contained" sx={{ marginTop: "20px" }}>
          {title}
        </SubmitBtn>
      </Box>
    </MainForm>
  );
};
