import { FC } from "react";
import { Box, Typography } from "@mui/material";

import { Form } from "./Form";
import { FormInput } from "./types";

import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../api/axiosConfig";

export const Create: FC = () => {
  const navigate = useNavigate();

  const onSubmit = async (data: FormInput) => {
    const result = await axiosInstance.post("specialists", data);
    if (result?.data?.id) navigate("/");
  };
  return (
    <Box>
      <Typography variant="h5" component="h5">
        Add an Specialist
      </Typography>
      <Form onSubmit={onSubmit} />
    </Box>
  );
};

export { Form as CreateSpecialistForm };
export type { FormInput as CreateSpecialistFormInput };
