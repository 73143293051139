import { FC } from "react";

import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { useFormContext } from "./FormContext";
import SendIcon from "@mui/icons-material/Send";

type Props = ButtonProps & {};

export const SubmitBtn: FC<Props> = (props) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Button
      type="submit"
      fullWidth
      disabled={isSubmitting}
      endIcon={!isSubmitting ? <SendIcon /> : <CircularProgress size={20} />}
      {...props}
    >
      {props.children || "Submit"}
    </Button>
  );
};
