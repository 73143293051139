import { FC } from "react";
import { Outlet } from "react-router-dom";
import { CreateSpecialist } from "./CreateSpecialist";
import { CreateService } from "./CreateService";
import { Welcome } from "./Welcome";

export const Onboarding: FC = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export { Welcome, CreateService, CreateSpecialist };
