import React from "react";
import styled from "@emotion/styled";

const Dot = styled.div<{ delay: string }>`
  background-color: #fff;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 2px;
  animation: bounce 1.2s infinite;
  animation-delay: ${(props) => props.delay};

  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

export const LoadingDots: React.FC = () => {
  return (
    <div style={{ display: "inline-block" }}>
      <div style={{ display: "flex" }}>
        <Dot delay="0s" />
        <Dot delay=".3s" />
        <Dot delay=".6s" />
      </div>
    </div>
  );
};
