import { FC } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormInput, schema } from "../parts/Form";
import { PageFormModal } from "../../../layouts/PageFormModal";
import { axiosInstance } from "../../../api/axiosConfig";
import { useParams } from "react-router-dom";
import { useSectionData } from "../../../stores/section";
import { ScheduleEventApi } from "../../../types";
import { dispatchToast } from "../../../components/Toast";
import { strToDate } from "../../../../utils/dateConvertor";
import { LoadingCover } from "../../../components/Loading";
import { URL } from "../constants";
import { usePaginatedData } from "../../../stores/pagination";
import { useAppState } from "../../../stores/appMain";

export const UpdateModal: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: list, mutate } = usePaginatedData<ScheduleEventApi>(URL, null, true);
  const storedItem = list?.find((item) => item.id === +id!);
  const { data: dataItem, loading } = useSectionData<ScheduleEventApi>(storedItem ? null : `${URL}/${id}`);
  const initForm = storedItem || dataItem;

  const services = useAppState
    .services()
    .value.map((item) => ({ value: item.id, label: item.title, data: { ...item } }));
  const specialists = useAppState.specialists().value.map(({ id: value, name: label }) => ({ value, label }));

  const onUpdate = async (values: FormInput) => {
    if (!values.id) return;
    const { data } = await axiosInstance.patch<ScheduleEventApi>(`${URL}/${values.id}`, values);
    if (data?.id) {
      mutate((values) => {
        return values?.map((item) => {
          if (item.id === data?.id) return { ...data };
          return item;
        });
      }, true);
      dispatchToast({ message: "Successfully updated." });
    }
  };

  return (
    <PageFormModal>
      {!loading ? (
        <Form
          onSubmit={onUpdate}
          init={{
            ...initForm,
            startDate: strToDate(initForm?.startDate),
            endDate: strToDate(initForm?.endDate),
          }}
          resolver={zodResolver(schema)}
          services={services}
          specialists={specialists}
          isUpdate
        />
      ) : (
        <LoadingCover open={true} />
      )}
    </PageFormModal>
  );
};
